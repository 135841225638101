import PropTypes from 'prop-types'

import './index.scss'

const Badge = ({ color = 'primary', className = '', text = '', outline = false }) => {
  const badgeStyles = []
  badgeStyles.push(`badge-${color}${outline ? '-outline' : ''}`)
  if (className) badgeStyles.push(className)

  return <span className={badgeStyles.join(' ')}>{text}</span>
}

Badge.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  outline: PropTypes.bool,
  text: PropTypes.string,
}

export default Badge

import { Button, Col } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import PropTypes from 'prop-types'

const ImageCard = ({ index, url, onDelete }) => (
  <Col span={8}>
    <div className="thumbnail-wrapper">
      <img className="thumbnail" src={url} alt="" />
      <div className="thumb-overlay">
        <Button
          type="danger"
          icon={<DeleteFilled />}
          size="small"
          onClick={() => onDelete(index)}
          className="btn-delete"
        />
      </div>
    </div>
  </Col>
)

ImageCard.propTypes = {
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
}

export default ImageCard

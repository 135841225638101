import { Space, Switch } from 'antd'
import { collectionGroup, doc, getDocs, orderBy, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { db } from '@/services/firebase'
import AppPageHeader from '../../../components/app-page-header'
import DataList from './data-list'

import './index.scss'

function Participants() {
  const location = useLocation()

  const [allParticipants, setAllParticipants] = useState([])
  const [participants, setParticipants] = useState([])

  let release_id = null
  let shop_id = null

  if (location.state != null) {
    release_id = location.state.release_id
    shop_id = location.state.shop_id
  }

  useEffect(() => {
    const releaseRef = doc(db, 'releases', release_id)
    const shopRef = doc(db, 'shops', shop_id)

    getDocs(
      query(
        query(
          query(collectionGroup(db, 'user_raffles'), where('release_ref', '==', releaseRef)),
          where('entered_shop_refs', 'array-contains', shopRef),
        ),
        orderBy('entry_date'),
      ),
    ).then(function (querySnapshot) {
      const dbParticipants = querySnapshot.docs.map(function (firestoreDoc) {
        let data = {
          id: firestoreDoc.id,
          ref: firestoreDoc.ref.parent,
          e_date: moment(firestoreDoc.get('entry_date').toDate()).format('DD.MM.YYYY, HH:mm'),
          ...firestoreDoc.data(),
        }

        if (
          firestoreDoc.get('deviceData') !== undefined &&
          firestoreDoc.get('deviceData.ip') !== null
        ) {
          data['user_ip_address'] = firestoreDoc.get('deviceData.ip')
        }

        console.log('data: ', data)

        return data
      })

      // Find duplicates
      let sorted_arr = dbParticipants.slice().sort((a, b) => {
        if (a.user_ip_address != null && b.user_ip_address != null) {
          return a.user_ip_address.localeCompare(b.user_ip_address)
        }
        return true
      })
      let duplicate_ips = []
      for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (
          sorted_arr[i].user_ip_address != null &&
          sorted_arr[i + 1].user_ip_address === sorted_arr[i].user_ip_address
        ) {
          duplicate_ips.push(sorted_arr[i].user_ip_address)
        }
      }
      console.log('duplicates: ', duplicate_ips)

      // Mark duplicates in main array
      for (let i = 0; i <= dbParticipants.length - 1; i++) {
        // console.log(i);
        dbParticipants[i].is_duplicate =
          duplicate_ips.findIndex((ip) => ip === dbParticipants[i].user_ip_address) > -1
      }
      // console.log("dbParticipants: ", dbParticipants);

      setAllParticipants(dbParticipants)
      setParticipants(dbParticipants)
    })
  }, [])

  function onChangeShowDuplicates(checked) {
    if (checked) {
      let filtered = allParticipants.filter((p) => p.is_duplicate)
      setParticipants(filtered)
    } else {
      setParticipants(allParticipants)
    }
  }

  function onChangeShowElites(checked) {
    if (checked) {
      let filtered = allParticipants.filter((p) => p.is_elite)
      setParticipants(filtered)
    } else {
      setParticipants(allParticipants)
    }
  }

  return (
    <div className="app-raffle-participants">
      <AppPageHeader title="Participants" />

      <div className="filters-wrapper">
        <Space size="middle">
          <Switch onChange={onChangeShowDuplicates} /> Show users from the same IP only
          <Switch onChange={onChangeShowElites} /> Show elites only
        </Space>
      </div>

      <div className="table-wrapper round-top-1 mt-3">
        <DataList participants={participants} />
      </div>
    </div>
  )
}

export default Participants

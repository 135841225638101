import { Badge, Button, Table, message } from 'antd'
import { WarningTwoTone } from '@ant-design/icons'
import { getDoc, updateDoc } from 'firebase/firestore'

const HtmlToReactParser = require('html-to-react').Parser
const addressFormatter = require('@fragaria/address-formatter')

const DataList = ({ participants }) => {
  const htmlToReactParser = new HtmlToReactParser()

  function address(data) {
    let userAddress = addressFormatter.format(
      {
        road: data.street || null,
        city: data.city || null,
        postcode: data.zipCode || null,
        state: data.state || null,
        countryCode: data.country || null,
      },
      {
        appendCountry: true,
      },
    )

    userAddress = userAddress.replace(/^\s+|\s+$/g, '').replace(/\n/g, '<br />') // remove last \n and replace others with a br

    if (data.flat) {
      userAddress = data.flat + '<br />' + userAddress
    }

    return userAddress
  }

  function deviceData(data) {
    let arr = [
      `IP: <a href="https://whatismyipaddress.com/ip/${data.ip}" target="_blank">${data.ip}</a>`,
      `Device: ${data.platform} ${data.version} (${data.locale})`,
      `Version: ${data.app_version}`,
    ]
    return arr.join('<br />')
  }

  function toggle_ban(participant, e) {
    const user_ref = participant.ref.parent
    const source_button = e.currentTarget

    getDoc(user_ref)
      .then((firestoreDoc) => {
        if (firestoreDoc.exists) {
          const isBanned = !!firestoreDoc.data()['is_banned']

          ban(user_ref, source_button, !isBanned)
        }
      })
      .catch()
  }

  const ban = (userRef, sourceButton, isBanned) => {
    updateDoc(userRef, {
      is_banned: isBanned,
    })
      .then(() => {
        sourceButton.innerText = isBanned ? 'Unban' : 'Ban'
      })
      .catch(async () => {
        await message.error('Failed to (un)ban this user. See error message in the console.')
      })
  }

  const columns = [
    {
      title: 'Elite',
      width: 30,
      align: 'center',
      render: (t, record) => (record.is_elite ? <Badge color="orange" title="Elite" /> : null),
    },
    {
      title: 'Is it duplicate?',
      dataIndex: 'is_duplicate',
      width: 160,
      render: (txt, record) => {
        if (record.is_duplicate) {
          return <WarningTwoTone twoToneColor="#ff0000" />
        }
      },
    },
    {
      title: 'Name',
      dataIndex: 'user_name',
      sorter: (a, b) => {
        return a.user_name.localeCompare(b.user_name)
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      sorter: (a, b) => {
        let aCity = ''
        let bCity = ''
        if (a.address && a.address.city) {
          aCity = a.address.city
        } else if (a.city) {
          aCity = a.city
        }

        if (b.address && b.address.city) {
          bCity = b.address.city
        } else if (b.city) {
          bCity = b.city
        }

        return aCity.localeCompare(bCity)
      },
      render: (txt, rec) => {
        if (rec.address) {
          let htmlInput = address(rec.address)
          return htmlToReactParser.parse(htmlInput)
        } else {
          return rec.city
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'user_email',
      sorter: (a, b) => {
        return a.user_email.localeCompare(b.user_email)
      },
    },
    {
      title: 'Entry date',
      dataIndex: 'e_date',
      width: 160,
      sorter: (a, b) => a.entry_date - b.entry_date,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      width: 70,
      sorter: (a, b) => a.size - b.size,
      render: (txt, rec) => {
        if (rec.size === 8000) {
          return '-'
        } else {
          return txt
        }
      },
    },
    {
      title: 'Device',
      dataIndex: 'deviceData',
      width: 250,
      sorter: (a, b) => {
        // FIXME: mix of old & new
        if (
          a.deviceData &&
          b.deviceData &&
          a.deviceData.ip &&
          b.deviceData.ip &&
          a.deviceData.ip &&
          b.deviceData.ip
        ) {
          return a.deviceData.ip.localeCompare(b.deviceData.ip)
        } else if (a.user_ip_address && b.user_ip_address) {
          return a.user_ip_address.localeCompare(b.user_ip_address)
        }
        return true
      },
      render: (txt, rec) => {
        if (rec.deviceData) {
          let htmlInput = deviceData(rec.deviceData)
          return htmlToReactParser.parse(htmlInput)
        }
        if (rec.user_ip_address) {
          return rec.user_ip_address
        } else {
          return ' '
        }
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 120,
      align: 'right',
      render: (text, record) => (
        <Button onClick={(e) => toggle_ban(record, e)}>
          {' '}
          {record.is_banned ? 'Unban' : 'Ban'}{' '}
        </Button>
      ),
    },
  ]

  return <Table bordered dataSource={participants} columns={columns} rowKey="id" />
}

export default DataList

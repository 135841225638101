import { Button, Space, Table } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'

import { auth, db } from '@/services/firebase'
import { logDebug } from '@/helpers/utils'
import { toastSuccess, toastError } from '@/components/app-toast'
import AppPageHeader from '@/components/app-page-header'
import RouteRegistry from '@/constants/route-registry'

import './index.scss'
import PropTypes from 'prop-types'

const addressFormatter = require('@fragaria/address-formatter')

const StoreLocationsPage = ({ shopId }) => {
  const [locations, setLocations] = useState([])
  const currentUser = auth.currentUser
  const navigate = useNavigate()

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(doc(db, 'shops', shopId), 'locations'), orderBy('street', 'asc')),
      (snapshot) => {
        setLocations(
          snapshot.docs.map((firestoreDoc) => ({
            id: firestoreDoc.id,
            ...firestoreDoc.data(),
          })),
        )
      },
    )

    return () => {
      unsub()
    }
  }, [])

  const formatAddress = (record) => {
    let addressData = {
      city: record.city,
      road: record.street,
      postcode: record.postal_code,
      state: record.state,
    }

    if (record.country_code instanceof Object) {
      addressData['country'] = record.country_code.name
    } else {
      addressData['countryCode'] = record.country_code
    }

    const address = addressFormatter.format(addressData, {
      appendCountry: true,
    })

    return address.replace(/^\s+|\s+$/g, '').replace(/\n/g, '<br />') // remove last \n and replace others with a br;
  }

  const deleteLocation = async (record) => {
    try {
      await deleteDoc(doc(doc(db, 'shops', shopId), 'locations', record.id))

      if (record?.hq) {
        await updateDoc(doc(db, 'shops', shopId), { hq: null })
      }

      toastSuccess('Successfully deleted the store location.')
    } catch (e) {
      logDebug('>>> DELETING STORE LOCATION FAILED', e.response ? e.response : e)
      toastError("Something went wrong. Location couln't be deleted.")
    }
  }

  const columns = [
    {
      title: 'Address',
      dataIndex: 'address',
      render: (text, record) => <div dangerouslySetInnerHTML={{ __html: formatAddress(record) }} />,
    },
    {
      title: 'Actions',
      width: 200,
      align: 'center',
      render: (record) => (
        <Space size="middle">
          <Button className="btn-outline-danger" danger onClick={() => deleteLocation(record)}>
            Delete
          </Button>
          <Link to="/store-admin/stores/store" state={{ store: record }}>
            <Button className="btn-outline-primary">Edit</Button>
          </Link>
        </Space>
      ),
    },
  ]

  const onClickNewStore = () => {
    navigate('/store-admin/stores/store')
  }

  return (
    <div className="app-store-locations">
      <AppPageHeader
        title="Locations"
        mainActionEnabled={true}
        mainActionLabel="New Location"
        onClick={onClickNewStore}
      />

      <div className="data-wrapper round-top-1">
        <Table bordered dataSource={locations} columns={columns} rowKey="id" />
      </div>
    </div>
  )
}

StoreLocationsPage.propTypes = {
  shopId: PropTypes.string.isRequired,
}

export default StoreLocationsPage

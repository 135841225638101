import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import { initializeApp } from 'firebase/app'
import admin from 'firebase-admin'

export const app = initializeApp(
  process.env.REACT_APP_DEPLOY_ENV === 'prod'
    ? {
        apiKey: 'AIzaSyDZCO83Q0QyFZczzfJVRBnvn2hhfqWozG0',
        appId: '1:44848260345:web:e1fcb2649997a402afae28',
        authDomain: 'sneakerdraws-65e4a.firebaseapp.com',
        databaseURL: 'https://sneakerdraws-65e4a.firebaseio.com',
        projectId: 'sneakerdraws-65e4a',
        storageBucket: 'sneakerdraws-65e4a.appspot.com',
        messagingSenderId: '44848260345',
        measurementId: 'G-3GCRNE69K2',
      }
    : {
        apiKey: 'AIzaSyAtVEySl0wOdk9d3A2iiyfz3VeH8JbgMno',
        appId: '1:925946545279:web:3ad7668bc5631f7e03ab4a',
        authDomain: 'sneakerdraws-staging.firebaseapp.com',
        databaseURL: 'https://sneakerdraws-staging.firebaseio.com',
        projectId: 'sneakerdraws-staging',
        storageBucket: 'sneakerdraws-staging.appspot.com',
        messagingSenderId: '925946545279',
      },
)

export const auth = getAuth(app)
export const db = getFirestore(app)
export const dbFieldValue = admin.firestore.FieldValue
export const functions = getFunctions(app, 'europe-west3')
export const storage = getStorage(app)

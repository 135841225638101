import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { StrictMode } from 'react'
import { StyleProvider } from '@ant-design/cssinjs'
import { createRoot } from 'react-dom/client'

import * as serviceWorker from './serviceWorker'
import { configureStore } from './redux/store'
import App from './App'

import './index.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-utilities.min.css'
import { ConfigProvider, theme } from 'antd'

const root = createRoot(document.getElementById('root'))

root.render(
  <StrictMode>
    {/*<ConfigProvider*/}
    {/*  theme={{*/}
    {/*    // 1. Use dark algorithm*/}
    {/*    algorithm: theme.defaultAlgorithm,*/}

    {/*    // 2. Combine dark algorithm and compact algorithm*/}
    {/*    // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],*/}
    {/*  }}*/}
    {/*>*/}
    <Provider store={configureStore()}>
      <BrowserRouter>
        <StyleProvider hashPriority="high">
          <App />
        </StyleProvider>
      </BrowserRouter>
    </Provider>
    {/*</ConfigProvider>*/}
  </StrictMode>,
)
serviceWorker.unregister()

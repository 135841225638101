import { Form, Input, Button } from 'antd'
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { useState } from 'react'

import { auth } from '@/services/firebase'
import { logDebug } from '@/helpers/utils'
import { toastError, toastSuccess } from '@/components/app-toast'

import './index.scss'

const ChangePassword = () => {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()

  const onFinish = (values) => {
    setLoading(true)

    const user = auth.currentUser
    const credential = EmailAuthProvider.credential(user.email, values.old_password)

    reauthenticateWithCredential(user, credential)
      .then(function () {
        updatePassword(user, values.password)
          .then(function () {
            toastSuccess('Password changed successfully')
            form.setFieldsValue({ password: '', confirm: '', old_password: '' })
            setLoading(false)
          })
          .catch(function (error) {
            logDebug('>>> UPDATING PASSWORD FAILED', error)
            toastError("Password couldn't be changed")
            setLoading(false)
          })
      })
      .catch(function (error) {
        logDebug('>>> UPDATING PASSWORD FAILED', error)
        toastError('Current password is invalid.')
        setLoading(false)
      })
  }

  const passwordValidator = (rule, value) => {
    if (value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm)) {
      return Promise.resolve()
    }
    return Promise.reject(
      'Your password must include at least 8 characters including at least 1 uppercase letter, 1 lowercase letter, and 1 number.',
    )
  }

  return (
    <div className="app-update-password">
      <h1 className="app-section-title">Change Password</h1>
      <div className="form-wrapper box-shadow">
        <p className="help-text">
          Create a unique password to protect your account or{' '}
          <span className="font-bold text-underline">Contact us</span> if you forgot your password
        </p>
        <Form
          form={form}
          name="password_form"
          layout="horizontal"
          labelCol={{ span: 6 }}
          onFinish={onFinish}
        >
          <Form.Item
            name="old_password"
            label="Current password"
            rules={[
              {
                required: true,
                message: 'Enter current password',
              },
            ]}
            hasFeedback
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            label="New password"
            rules={[
              {
                required: true,
                message: 'Enter new password',
              },
              { validator: passwordValidator },
            ]}
            hasFeedback
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Re-enter new password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Retype new password',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }

                  return Promise.reject('The two passwords that you entered do not match.')
                },
              }),
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" className="btn-submit" loading={loading}>
              Update password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default ChangePassword

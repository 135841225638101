import moment from 'moment'

const formatNumber = (value) => {
  if (value) {
    if (typeof value === 'number') return value.toLocaleString('ru-RU')
    else return parseInt(value).toLocaleString('ru-RU')
  } else {
    return 0
  }
}

const formatDate = (dateTime, format) => {
  if (dateTime) {
    return moment(dateTime.toDate()).format(format)
  } else {
    return null
  }
}

export { formatNumber, formatDate }

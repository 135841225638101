import { DollarOutlined, HomeOutlined, InboxOutlined, ShopOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Divider, Select, Space, Table, Tag, message } from 'antd'
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { db, dbFieldValue, functions } from '@/services/firebase'

const addressFormatter = require('@fragaria/address-formatter')
const HtmlToReactParser = require('html-to-react').Parser

const { Option } = Select

const Winners = ({ shopId }) => {
  const [winners, setWinners] = useState([])
  const location = useLocation()
  const htmlToReactParser = new HtmlToReactParser()

  useEffect(() => {
    const unsub = onSnapshot(
      query(
        collection(doc(doc(db, 'shops', shopId), 'raffles', location.state.raffle_id), 'winners'),
        orderBy('size', 'asc'),
      ),
      (snapshot) => {
        const _winners = snapshot.docs.map((firestoreDoc) => ({
          id: firestoreDoc.id,
          ...firestoreDoc.data(),
        }))
        console.log(_winners)
        setWinners(_winners)
      },
    )
    return () => {
      console.log('cleanup')
      unsub()
    }
  }, [])

  function downloadPDF() {
    const formattedReleaseDate = moment(location.state.release_date.toDate()).format('DD.MM.YYYY')
    const getWinnersPDF = httpsCallable(functions, 'getWinnersPDF')

    getWinnersPDF({
      winners: winners,
      releaseDate: formattedReleaseDate,
      releaseTitle: location.state.release_title,
    }).then(function (result) {
      if (result.data == null) {
        message.error({ content: "Can't create pdf." })
      } else {
        console.log(result.data)

        const pdf = result.data
        const linkSource = `data:application/pdf;base64,${pdf}`
        const downloadLink = document.createElement('a')
        const fileName = 'winners.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
    })
  }

  // TODO: Над этим надо подумать, ведь теперь у нас может быть объект country
  function address(data) {
    let userAddress = addressFormatter.format(
      {
        // "houseNumber": 301,
        road: data.street || null,
        // "neighbourhood": "Crescent Park",
        city: data.city || null,
        postcode: data.zipCode || null,
        // "county": "Santa Clara County",
        state: data.state || null,
        // "country": "United States of America",
        countryCode: data.country || null,
      },
      {
        appendCountry: true,
      },
    )

    userAddress = userAddress.replace(/^\s+|\s+$/g, '').replace(/\n/g, '<br />') // remove last \n and replace others with a br

    if (data.flat !== undefined && data.flat !== null) {
      userAddress = data.flat + '<br />' + userAddress
    }

    return userAddress
  }

  function handlePickUpSelect(value, previousValue, email) {
    if (value === previousValue) {
      return
    }

    getDocs(
      query(
        collection(doc(doc(db, 'shops', shopId), 'raffles', location.state.raffle_id), 'winners'),
        where('email', '==', email),
      ),
    ).then((docs) => {
      docs.forEach((firestoreDoc) => {
        // TODO: Плохая была идея ...
        if (typeof value === 'boolean') {
          return updateDoc(firestoreDoc.ref, {
            pick_up: value,
          })
        } else {
          return updateDoc(firestoreDoc.ref, {
            pick_up: dbFieldValue.delete(),
          })
        }
      })
    })
  }

  const columns = [
    {
      align: 'center',
      title: 'Details',
      width: 80,
      render: (txt, rec) => {
        if (Object.hasOwn(rec, 'use_shipping') && rec.use_shipping) {
          const dollarIconStyles = {
            fontSize: '1.75em',
            marginLeft: '16px',
          }

          if (!Object.hasOwn(rec, 'is_paid') || !rec.is_paid) {
            dollarIconStyles.color = 'rgba(0, 0, 0, 0.25)'
          }

          return (
            <div
              style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', height: '2em' }}
            >
              <InboxOutlined style={{ fontSize: '1.75em' }} />
              <DollarOutlined style={dollarIconStyles} />
            </div>
          )
        }

        return <ShopOutlined style={{ fontSize: '1.75em' }} />
      },
    },
    {
      align: 'center',
      title: 'Elite',
      width: 30,
      render: (txt, record) =>
        record.is_elite ? <Tag color="#009F41">Yes</Tag> : <Tag color="#D1291D">No</Tag>,
    },
    {
      title: 'Full name',
      dataIndex: 'name',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      render: (txt, rec) => {
        return Object.hasOwn(rec, 'address') && rec.address
          ? htmlToReactParser.parse(address(rec.address))
          : Object.hasOwn(rec, 'city') && rec.city
            ? rec.city
            : '-'
      },
      sorter: (a, b) => a.address.city.length - b.address.city.length,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Size',
      width: 90,
      dataIndex: 'size',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.size - b.size,
      render: (txt, rec) => {
        return rec.size === 8000 ? '-' : txt
      },
    },
    {
      dataIndex: 'pick_up',
      title: 'Pick-up',
      width: 200,
      render: (t, record) => {
        if (record.use_shipping) {
          return <div />
        } else {
          return (
            <Select
              defaultValue={record.pick_up === undefined ? true : record.pick_up}
              style={{ width: 120 }}
              onChange={(value) => handlePickUpSelect(value, record.pick_up, record.email)}
            >
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          )
        }
      },
    },
  ]

  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Releases</Breadcrumb.Item>
        <Breadcrumb.Item>{location.state.release_title}</Breadcrumb.Item>
        <Breadcrumb.Item>Winners</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Button type="primary" onClick={() => downloadPDF()}>
          Download
        </Button>
        <Table bordered dataSource={winners} columns={columns} />
      </Space>
    </div>
  )
}

Winners.propTypes = {
  shopId: PropTypes.string.isRequired,
}

export default Winners

import { Button, Col, Form, Input, Row, Select, Spin, Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  writeBatch,
} from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

import { db, storage } from '@/services/firebase'
import AppPageHeader from '@/components/app-page-header'
import ChangePassword from './change-password'

import './index.scss'

const { TextArea } = Input
const { Option } = Select

function ShopData({ shopId }) {
  const [saving, setSaving] = useState(false)
  const [uploadingImg, setUploadingImg] = useState(false)
  const [fileList, setFileList] = useState([])
  const [bannerImages, setBannerImages] = useState([])
  const [locations, setLocations] = useState([])
  const [form] = Form.useForm()
  const [existingBanners, setExistingBanners] = useState([])

  useEffect(() => {
    const unsub1 = onSnapshot(
      query(collection(doc(db, 'shops', shopId), 'locations'), orderBy('street', 'asc')),
      (snapshot) => {
        const allLocations = snapshot.docs.map((firestoreDoc) => ({
          id: firestoreDoc.id,
          ...firestoreDoc.data(),
        }))
        console.log(allLocations)
        setLocations(allLocations)

        const hq = allLocations.find((loc) => loc.hq === true)
        if (hq != null) {
          form.setFieldsValue({ hq: hq.id })
        }
      },
    )

    const unsub2 = onSnapshot(doc(db, 'shops', shopId), (firestoreDoc) => {
      const shopData = firestoreDoc.data()

      form.setFieldsValue({
        name: shopData['title'],
        email: shopData['email'],
        description: shopData['description'],
        homepage: shopData['homepage'],
        instagram: shopData['instagram'],
        open_hours: shopData['open_hours'],
        phone: shopData['phone'],
      })

      if (shopData.banner_images !== null) {
        setExistingBanners(shopData.banner_images)
      }

      if (shopData.banner_images && shopData.banner_images.length > 0) {
        setBannerImages(shopData.banner_images)
      } else {
        setBannerImages([])
      }
    })

    return () => {
      console.log('cleanup')
      unsub1()
      unsub2()
    }
  }, [])

  const onFinish = (values) => {
    setSaving(true)

    let phone = values.phone.trim()
    if (phone.startsWith('+')) {
      phone = '00' + phone.slice(1)
    }

    const data = {
      title: values.name,
      email: values.email,
      description: values.description,
      homepage: values.homepage,
      instagram: values.instagram,
      open_hours: values.open_hours,
      phone: phone,
    }

    let location = locations.find((loc) => loc.id === values.hq)
    let locationCopy = Object.assign({}, location)
    if (locationCopy != null) {
      delete locationCopy.id
      delete locationCopy.hq
      data['hq'] = locationCopy
    }

    updateDoc(doc(db, 'shops', shopId), data)
      .then(async () => {
        const batch = writeBatch(db)

        for (let i = 0; i < locations.length; i++) {
          batch.update(doc(doc(db, 'shops', shopId), 'locations', locations[i].id), {
            hq: locations[i].id === values.hq,
          })
        }

        await batch.commit()
      })
      .then(async () => {
        setSaving(false)

        await message.success({ content: 'Data saved' })
      })
      .catch(async () => {
        setSaving(false)

        await message.error({ content: "Data couldn't be saved" })
      })
  }

  function uploadImage(file) {
    setUploadingImg(true)

    new Promise((resolve, reject) => {
      const originalFileObj = file

      console.log('Uploading:', originalFileObj)

      const type = originalFileObj.type === 'image/jpeg' ? '.jpeg' : '.png'

      const storageRef = ref(ref(ref(storage, 'shops'), shopId), uuidv4() + type)

      uploadBytes(storageRef, originalFileObj)
        .then(() => {
          getDownloadURL(storageRef).then((downloadURL) => {
            resolve(downloadURL)
          })
        })
        .catch(() => {
          reject('nope')
        })
    })
      .then((imgUrl) => updateContent(imgUrl))
      .catch(async () => {
        setSaving(false)
        await message.error({ content: "Image couldn't be saved" })
        setUploadingImg(false)
      })
  }

  const updateContent = async (imageUrl) => {
    try {
      await updateDoc(doc(db, 'shops', shopId), { banner_images: [imageUrl] })
    } finally {
      await setUploadingImg(false)
    }
  }

  const upload_props = {
    accept: 'image/jpeg, image/png',
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([file])
      uploadImage(file)
      return false
    },
    showUploadList: false,
  }

  const renderShopImage = () => {
    return bannerImages.map((imageUrl, index) => {
      return (
        <Spin key={`banner-image-${index}`} spinning={uploadingImg} tip="Saving...">
          <div className="r-1 overflow-hidden">
            <img className="shop-image" src={imageUrl} alt="Banner" />
          </div>
        </Spin>
      )
    })
  }

  return (
    <div className="app-shop-details">
      <AppPageHeader title="Shop Settings" />
      <Spin spinning={saving} tip="Saving...">
        <Row gutter={16} className="mt-3">
          <Col span={12}>
            <div className="form-wrapper box-shadow">
              <Form form={form} name="dynamic_form_item" layout="vertical" onFinish={onFinish}>
                <Form.Item
                  label="Shop name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Enter the shop name',
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Enter shop email',
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item className="mt-3" label="Description" name="description">
                  <TextArea size="large" rows={6} />
                </Form.Item>
                <Form.Item name="hq" className="mt-3" label="Headquarters">
                  <Select size="large" defaultValue="">
                    {locations.map((item) => (
                      <Option value={item.id}>
                        {item.street}, {item.city}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  className="mt-3"
                  label="Homepage"
                  name="homepage"
                  rules={[
                    {
                      type: 'url',
                      message: 'Invalid url. Please use http:// or https:// prefix.',
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item className="mt-3" label="Phone" name="phone">
                  <Input size="large" />
                </Form.Item>
                <Form.Item className="mt-3" label="Open hours" name="open_hours">
                  <TextArea size="large" rows={3} />
                </Form.Item>
                <Form.Item className="mt-3" label="Instagram" name="instagram">
                  <Input size="large" prefix="@" />
                </Form.Item>
                <Button htmlType="submit" className="btn-submit">
                  Save
                </Button>
              </Form>
            </div>
          </Col>
          <Col span={12}>
            <div>
              {renderShopImage()}
              <Upload
                className="img-upload-wrapper mt-2"
                name="file"
                fileList={fileList}
                {...upload_props}
              >
                <Button>
                  <UploadOutlined /> Select Banner Image
                </Button>
              </Upload>
            </div>
            <ChangePassword />
          </Col>
        </Row>
      </Spin>
    </div>
  )
}

ShopData.propTypes = {
  shopId: PropTypes.string.isRequired,
}

export default ShopData

import {
  INITIALIZE_RELEASES,
  SELECTED,
} from '../actions';
// import { getCurrentUser } from '../../helpers/Utils';

const INIT_STATE = {
  // currentUser: getCurrentUser(): null,
  releases: [],
  selected: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INITIALIZE_RELEASES: {
      const updatedState = {
        releases: [...action.payload],
      };

      return updatedState;
    }
    case SELECTED: {
      const updatedState = {
        ...state,
        selected: { ...action.payload },
      };

      return updatedState;
    }
    default:
      return { ...state };
  }
};
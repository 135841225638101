import {
  INITIALIZE_USER_INFO,
} from '../actions';
// import { getCurrentUser } from '../../helpers/Utils';

const INIT_STATE = {
  // currentUser: getCurrentUser(): null,
  currentUser: {
    authenticated: false,
    role: "", // admin || shop_admin
    shopId: "", // in case of shop_admin
    loading: true,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INITIALIZE_USER_INFO: {
      const updatedState = {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };

      return updatedState;
    }
    default:
      return { ...state };
  }
};
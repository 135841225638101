import styled from 'styled-components'

export const SDStyledContainerForNotAuthenticatedUser = styled.div`
  align-items: center;
  background-color: #002140;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 1rem;
`

import {
  AppstoreOutlined,
  NotificationOutlined,
  ShopOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useState } from 'react'

import RouteRegistry from '@/constants/route-registry'
import SideNavHead from '@/components/side-nav-head'

const { Content, Footer, Sider } = Layout

export default () => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const onCollapse = (collapsed) => setIsCollapsed(collapsed)

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={onCollapse}
        width={250}
        className="sd-bg-primary"
      >
        <SideNavHead />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" className="sd-bg-primary">
          <Menu.Item icon={<AppstoreOutlined />} key="1">
            <Link to="/admin/releases">Releases</Link>
          </Menu.Item>
          <Menu.Item icon={<NotificationOutlined />}>
            <Link to="/admin/posts">Posts</Link>
          </Menu.Item>
          <Menu.Item icon={<ShopOutlined />}>
            <Link to="/admin/stores">Stores</Link>
          </Menu.Item>
          <Menu.Item icon={<UsergroupAddOutlined />}>
            <Link to="/admin/users">Users</Link>
          </Menu.Item>
          <Menu.Item icon={<UsergroupAddOutlined />}>
            <a href="https://analytics.google.com" rel="noreferrer" target="_blank">
              Stats
            </a>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Sneakerdraws ©2020, v.{process.env.REACT_APP_VERSION} ({process.env.REACT_APP_DEPLOY_ENV}
          )
        </Footer>
      </Layout>
    </Layout>
  )
}

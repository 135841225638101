import { Button, Table } from 'antd'
import { collection, doc, onSnapshot, orderBy, query } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import { db } from '@/services/firebase'
import AppPageHeader from '@/components/app-page-header'

import './index.scss'

export default () => {
  const { shopId } = useParams()
  const navigate = useNavigate()

  const [raffles, setRaffles] = useState([])

  useEffect(() => {
    let unsub

    if (shopId) {
      unsub = onSnapshot(
        query(collection(doc(db, 'shops', shopId), 'raffles'), orderBy('release_date', 'desc')),
        (snapshot) => {
          const allRaffles = snapshot.docs.map((firestoreDoc) => ({
            id: firestoreDoc.id,
            rel_date: moment(firestoreDoc.get('release_date').toDate()).format('DD.MM.YYYY, HH:mm'),
            ...firestoreDoc.data(),
          }))
          setRaffles(allRaffles)
        },
      )
    } else {
      navigate(-1)
    }

    return () => {
      if (unsub) unsub()
    }
  }, [])

  const columns = [
    {
      title: 'Title',
      dataIndex: 'release_title',
      sorter: (a, b) => {
        return a.release_title.localeCompare(b.release_title)
      },
    },
    {
      title: 'Release Date',
      dataIndex: 'rel_date',
      width: 180,
      sorter: (a, b) => a.release_date - b.release_date,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Action',
      width: 200,
      align: 'right',
      render: (record) => (
        <Button
          onClick={() =>
            navigate('/admin/stores/participants', {
              state: {
                release_id: record.release_ref.id,
                shop_id: shopId,
                shop_title: '',
                release_title: record.release_title,
              },
            })
          }
          className="btn-outline-primary"
        >
          Participants
        </Button>
      ),
    },
  ]

  return (
    <div className="app-shop-raffles">
      <AppPageHeader
        title="Raffles"
        mainActionEnabled={true}
        mainActionLabel="Go Back"
        onClick={() => navigate(-1)}
      />

      <div className="table-wrapper border-top-radius">
        <Table bordered dataSource={raffles} columns={columns} rowKey="id" />
      </div>
    </div>
  )
}

import { Button, Form, Input, Modal, Space, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Navigate, useLocation } from 'react-router-dom'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ReactQuill, { Quill } from 'react-quill'
import ImageUploader from 'quill-image-uploader'

import { db, dbFieldValue, storage } from '@/services/firebase'

import 'react-quill/dist/quill.snow.css'
import './index.scss'

const { confirm } = Modal

Quill.register('modules/imageUploader', ImageUploader)

export default () => {
  const [form] = Form.useForm()
  const location = useLocation()
  const [shouldPublish, setShouldPublish] = useState(false)
  const [quillValue, setQuillValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [delta, setDelta] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [contentUpdated, setContentUpdated] = useState(false)

  const post = location.state && location.state.post ? location.state.post : null
  const postId =
    location.state && location.state.postId
      ? location.state.postId
      : post
        ? post.id
        : doc(collection(db, 'feed_posts')).id

  useEffect(() => {
    console.log('effect')
    if (post !== null) {
      setQuillValue(post.content)
    }
  }, [])

  const onFinish = (values) => {
    setLoading(true)

    const data = {
      title: values.title,
      content: quillValue,
    }

    const images = delta
      .filter((op) => typeof op.insert === 'object' && op.insert.image !== undefined)
      .map((op) => op.insert.image)

    if (images.length > 0) {
      data.banner_url = images[0]
    }

    if (post === null) {
      data.language = 'en'
      data.published = shouldPublish
      data.publish_date = new Date()

      setDoc(doc(db, 'feed_posts', postId), data)
        .then(function () {
          console.log('Document successfully written!')
          setRedirect('/admin/posts')
        })
        .catch(function (error) {
          console.error('Error writing document: ', error)
        })
    } else {
      updateDoc(doc(db, 'feed_posts', postId), data)
        .then(function () {
          console.log('Document successfully written!')
          setRedirect('/admin/posts')
        })
        .catch(function (error) {
          console.error('Error writing document: ', error)
        })
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const modules = useMemo(
    () => ({
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ align: [] }, { color: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
      imageUploader: {
        upload: (file) => {
          return uploadImage(file)
        },
      },
    }),
    [],
  )

  // LATER: resize
  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const type = file.type === 'image/jpeg' ? '.jpeg' : '.png'
      const storageRef = ref(ref(ref(storage, 'feed_posts'), postId), uuidv4() + type)
      console.log('storageRef: ', storageRef.fullPath)

      uploadBytes(storageRef, file)
        .then((s) => {
          console.log('Uploaded a blob or file!')
          getDownloadURL(storageRef).then((downloadURL) => {
            console.log('File available at', downloadURL)
            resolve(downloadURL)
          })
        })
        .catch((error) => {
          console.error('Error uploading: ', error)
          reject('Upload failed.')
        })
    })
  }

  const showSaveAndPublishConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        confirm({
          title: 'Are you sure you want to publish this post?',
          icon: <ExclamationCircleOutlined />,
          content: 'All users will be notified immediately.',
          okText: 'Save & publish',
          cancelText: 'Cancel',
          onOk() {
            // message.error('Done.');
            setShouldPublish(true)
            form.submit()
          },
        })
      })
      .catch((errorInfo) => {
        console.error('errorInfo: ', errorInfo)
      })
  }

  const onChangeEditor = (content, delta, source, editor) => {
    setQuillValue(content)
    setDelta(editor.getContents())
    setContentUpdated(true)
  }

  if (redirect != null) {
    return <Navigate to={redirect} />
  }

  return (
    <div>
      {/* <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item>
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item>Posts</Breadcrumb.Item>
        <Breadcrumb.Item>
          {post != null ? "Edit" : "Create"}
        </Breadcrumb.Item>
      </Breadcrumb> */}

      <Spin spinning={loading} tip="Saving...">
        <Form
          form={form}
          name="basic"
          initialValues={{
            title: post && post.title,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Enter the post title',
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
          <div className="app">
            <ReactQuill
              bounds={'.app'}
              modules={modules}
              theme="snow"
              value={quillValue}
              onChange={onChangeEditor}
            />
          </div>
          <Space>
            <Button
              className="btn-submit"
              onClick={() => {
                form.submit()
              }}
              disabled={!contentUpdated}
            >
              {post === null ? 'Create' : 'Save'}
            </Button>
            {post === null && (
              <Button
                className="btn-submit"
                onClick={showSaveAndPublishConfirm}
                disabled={!contentUpdated}
              >
                Create &amp; publish
              </Button>
            )}
          </Space>
        </Form>
      </Spin>
    </div>
  )
}

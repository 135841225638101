import React from 'react'
import { Navigate, Route, Routes as DomRoutes } from 'react-router-dom'

import RouteRegistry from '../../constants/route-registry'
import { auth } from 'firebase-admin'

const Releases = React.lazy(() => import('./releases'))
const Winners = React.lazy(() => import('./Winners'))
const StoreLocations = React.lazy(() => import('./store-locations'))
const ShopData = React.lazy(() => import('./shop-data'))
const StoreEditor = React.lazy(() => import('./store-locations/store-location-edit'))
const CreateRaffle = React.lazy(() => import('./create-raffle'))

const Routes = () => {
  const currentUser = auth.currentUser

  return (
    <DomRoutes>
      {/*<Navigate*/}
      {/*  exact*/}
      {/*  from={`${match.url}`}*/}
      {/*  to={`${match.url}${RouteRegistry.storeAdminRoot.subroutes.releases.path}`}*/}
      {/*/>*/}
      <Route
        exact
        path={RouteRegistry.storeAdminRoot.subroutes.releases.path}
        render={(props) => <Releases shop_id={currentUser.shopId} {...props} />}
      />
      <Route
        exact
        path={`${RouteRegistry.storeAdminRoot.subroutes.releases.path}${RouteRegistry.storeAdminRoot.subroutes.releases.subroutes.winners.path}`}
        render={(props) => <Winners shopId={currentUser.shopId} {...props} />}
      />
      <Route
        exact
        path={RouteRegistry.storeAdminRoot.subroutes.shop.path}
        render={(props) => <ShopData shopId={currentUser.shopId} {...props} />}
      />
      <Route
        exact
        path={RouteRegistry.storeAdminRoot.subroutes.stores.path}
        render={(props) => <StoreLocations currentUser={currentUser} {...props} />}
      />
      <Route
        exact
        path={RouteRegistry.storeAdminRoot.subroutes.newStoreLocation.path}
        render={(props) => <StoreEditor currentUser={currentUser} {...props} />}
      />
      <Route
        exact
        path={RouteRegistry.storeAdminRoot.subroutes.createRaffle.path}
        render={(props) => <CreateRaffle shopId={currentUser.shopId} {...props} />}
      />
      <Route
        exact
        path={RouteRegistry.storeAdminRoot.subroutes.editRaffle.path}
        render={(props) => <CreateRaffle shopId={currentUser.shopId} {...props} />}
      />
      <Route
        path="*"
        element={<Navigate to={`${RouteRegistry.storeAdminRoot.subroutes.releases.path}`} />}
      />
      {/*<Navigate from={`${match.url}`} to={`${match.url}${RouteRegistry.error.path}`} />*/}
    </DomRoutes>
  )
}

// <Route index element={<Home />} />
//         <Route path="about" element={<About />} />
//         <Route path="*" element={<NoMatch />} />

export default Routes

import { Button, Row, Upload } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import { arrayRemove, arrayUnion, doc, onSnapshot, updateDoc } from 'firebase/firestore'
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

import { db, storage, dbFieldValue } from '@/services/firebase'
import ImageCardForEditing from '@/pages/admin/releases-root/edit-release/Components/ImageCardForEditing'

const URI = require('urijs')

const EditImages = ({ releaseId }) => {
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const images_base_ref = ref(ref(storage, 'releases'), releaseId)

  useEffect(() => {
    const unsub = onSnapshot(doc(db, 'releases', releaseId), (snapshot) => {
      if (snapshot.data().images && snapshot.data().images.length > 0) {
        setImages(snapshot.data().images)
      } else {
        setImages([])
      }
    })

    return () => {
      unsub()
    }
  }, [])

  const deleteImage = async (index) => {
    const element = images[index]
    const decoded = decodeURIComponent(element)
    const fileName = new URI(decoded).filename()

    const releaseRef = doc(db, 'releases', releaseId)

    await updateDoc(releaseRef, {
      images: arrayRemove(element),
    })
    await deleteObject(ref(images_base_ref, fileName))
  }

  const uploadImage = (originalFileObj) => {
    return new Promise((resolve, reject) => {
      setLoading(true)

      console.log('Uploading:', originalFileObj)

      const type = originalFileObj.type === 'image/jpeg' ? '.jpeg' : '.png'
      const storageRef = ref(images_base_ref, uuidv4() + type)

      console.log('storageRef:' + storageRef.fullPath)

      uploadBytes(storageRef, originalFileObj)
        .then(() => {
          console.log('Uploaded a blob or file!')
          getDownloadURL(storageRef).then((downloadURL) => {
            resolve(downloadURL)
          })
          setLoading(false)
        })
        .catch((error) => {
          console.error('Error uploading: ', error)
          reject('error uploading')
          setLoading(false)
        })
    })
  }

  return (
    <div className="mb-4">
      <div className="ant-row ant-form-item mt-3">
        <p className="mt-3">Images</p>
        <Upload
          accept="image/jpeg,image/png"
          className="custom-upload w-100"
          listType="text"
          multiple={true}
          showUploadList={false}
          beforeUpload={(file, fileList) => {
            uploadImage(file).then(async (downloadURL) => {
              const releaseRef = doc(db, 'releases', releaseId)

              console.log(releaseId)

              await updateDoc(releaseRef, {
                images: arrayUnion(downloadURL),
              })
            })

            return false
          }}
        >
          <Button className="btn-upload w-100" loading={loading}>
            Select images <CloudUploadOutlined />
          </Button>
        </Upload>
      </div>
      <Row gutter={[16, 16]} className="mt-3">
        {images.map((img, i) => (
          <ImageCardForEditing key={i} url={img} index={i} onDelete={deleteImage} />
        ))}
      </Row>
    </div>
  )
}

EditImages.propTypes = {
  releaseId: PropTypes.string.isRequired,
}

export default EditImages

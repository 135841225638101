import moment from 'moment'

export const logDebug = (name, message, singleLine = false) => {
  if (process.env.REACT_APP_DEBUG === 'true') {
    if (singleLine) {
      console.log(`${name}: ${message}`)
    } else {
      console.log(name, message)
    }
  }
}

export const convertFirestoreTimestampToDate = (timestamp) => {
  return new Date(Math.round(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000))
}

import { Button, Image, Modal, Space, Table } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { collection, query, orderBy, onSnapshot, doc, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { db } from '@/services/firebase'
import AppPageHeader from '@/components/app-page-header'
import RouteRegistry from '@/constants/route-registry'

import './index.scss'

const { confirm } = Modal

export default () => {
  const [posts, setPosts] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, 'feed_posts'), orderBy('publish_date', 'desc')),
      (snapshot) => {
        const allPosts = snapshot.docs.map((firestoreDoc) => ({
          id: firestoreDoc.id,
          date_formatted: moment(firestoreDoc.get('publish_date').toDate()).format(
            'DD.MM.YYYY, HH:mm',
          ),
          ...firestoreDoc.data(),
        }))
        setPosts(allPosts)
      },
    )
    return () => {
      unsub()
    }
  }, [])

  const showPublishConfirm = (post, should_publish) => {
    confirm({
      title: should_publish
        ? 'Are you sure you want to publish this post?'
        : 'Are you sure you want to take offline this post?',
      icon: <ExclamationCircleOutlined />,
      content: post.title,
      okText: should_publish ? 'Publish' : 'Take offline',
      cancelText: 'Cancel',
      onOk() {
        updateDoc(doc(db, 'feed_posts', post.id), { published: should_publish })
          .then(function () {
            console.log('Document successfully written!')
          })
          .catch(function (error) {
            console.error('Error writing document: ', error)
          })
      },
    })
  }

  const columns = [
    {
      width: 20,
      title: 'Picture',
      dataIndex: 'banner_url',
      render: (value) => <Image width={60} src={value} />,
    },
    // {
    //   width: 20,
    //   align: 'center',
    //   render: (t, record) => (
    //     record.published == true
    //       ? (<Badge status="success" title="Online" />)
    //       : (<Badge status="default" title="Offline" />)
    //   )
    // },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: (a, b) => {
        return a.title.localeCompare(b.title)
      },
    },
    {
      title: 'Publish date',
      dataIndex: 'date_formatted',
      width: 164,
      sorter: (a, b) => a.publish_date - b.publish_date,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          {record.published ? (
            <Button
              className="btn-outline-primary btn-publish-unpublish"
              onClick={() => showPublishConfirm(record, false)}
            >
              Unpublish
            </Button>
          ) : (
            <Button
              className="btn-outline-primary btn-publish-unpublish"
              onClick={() => showPublishConfirm(record, true)}
            >
              Publish
            </Button>
          )}
          <Button
            className="btn-outline-primary"
            onClick={() =>
              navigate('/admin/posts/edit', {
                state: { post: record },
              })
            }
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="app-posts">
      <AppPageHeader
        title="Posts"
        mainActionEnabled={true}
        mainActionLabel="New Post"
        onClick={() => navigate('/admin/posts/new')}
      />

      <div className="posts-table-wrapper round-top-1">
        <Table bordered dataSource={posts} columns={columns} />
      </div>
    </div>
  )
}

// export default PostList

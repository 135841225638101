import React from 'react'
import { Button, Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import './index.scss'

const AppPageHeader = ({
  title = '',
  mainActionEnabled,
  mainActionLabel = '',
  mainActionIcon,
  onClick,
}) => {
  return (
    <Row justify="center" className="app-page-header">
      <Col flex="auto">
        <h1 className="title">{title}</h1>
      </Col>
      {mainActionEnabled && (
        <Col flex="100px">
          <Button
            className="btn-main-action"
            icon={mainActionIcon ? mainActionIcon : <PlusOutlined className="btn-icon" />}
            size="large"
            onClick={onClick}
          >
            {mainActionLabel}
          </Button>
        </Col>
      )}
    </Row>
  )
}

export default AppPageHeader

import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, Button, Image } from 'antd'

import AppPageHeader from '../../../../components/app-page-header'

import { db } from '../../../../services/firebase'

import './index.scss'

function StoreList() {
  const navigate = useNavigate()

  const [shops, setShops] = useState([])

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, 'shops'), orderBy('title')), (snapshot) => {
      const allShops = snapshot.docs.map((firestoreDoc) => ({
        id: firestoreDoc.id,
        ...firestoreDoc.data(),
      }))
      setShops(allShops)
    })

    return () => {
      unsub()
    }
  }, [])

  const getBannerImage = (imgArray) => (imgArray && imgArray.length > 0 ? imgArray[0] : '')

  const columns = [
    {
      width: 20,
      title: 'Logo',
      dataIndex: 'banner_images',
      render: (value) => <Image width={60} height={40} src={getBannerImage(value)} />,
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Action',
      width: 200,
      align: 'right',
      render: (record) => (
        <Button
          className="btn-outline-primary btn-raffle"
          onClick={() => {
            // console.log(`/admin/stores/raffles/${record.id}`)
            navigate(`/admin/stores/raffles/${record.id}`)
          }}
        >
          Raffles
        </Button>
      ),
    },
  ]

  return (
    <div className="app-stores">
      <AppPageHeader
        title="Stores"
        mainActionEnabled={true}
        mainActionLabel="New Store"
        onClick={() => {}}
      />

      <div className="stores-table-wrapper round-top-1">
        <Table bordered dataSource={shops} columns={columns} rowKey="id" />
      </div>
    </div>
  )
}

export default StoreList

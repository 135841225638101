import { combineReducers } from 'redux';

import authUser from './auth/reducer';
import raffleReleases from './releases/reducer';

const reducers = combineReducers({
  authUser,
  raffleReleases,
});

export default reducers;

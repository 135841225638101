import { Button, Form, Input, Spin, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import { auth } from '@/services/firebase'

import '../LoginSS.css'
import { SDStyledContainerForNotAuthenticatedUser } from '@/styles/shared/containers'
import { SDStyledPureWhiteSpan } from '@/styles/shared/labels'

const WelcomePage = ({ appState }) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const logOut = async () => {
    await auth.signOut()
    setIsLoggingIn(false)
  }

  const onFinish = async (values) => {
    setIsLoggingIn(true)

    await signInWithEmailAndPassword(auth, values.email, values.password).catch((error) => {
      setIsLoggingIn(false)
      message.error(error.message)
    })
  }

  let content

  if (appState.authenticated === undefined) {
    content = (
      <Fragment>
        <div>
          <SDStyledPureWhiteSpan className="fw-bold fs-5">
            Please, wait while we are checking your login state...
          </SDStyledPureWhiteSpan>
        </div>
        <Spin className="mt-4" size="large" />
      </Fragment>
    )
  } else {
    if (appState.authenticated) {
      content = (
        <Fragment>
          <div>
            <SDStyledPureWhiteSpan className="fw-bold fs-5">
              Sorry, seems like you login with unexpected role for our admin page...
            </SDStyledPureWhiteSpan>
          </div>
          <Button type="primary" htmlType="submit" className="mt-3" onClick={logOut}>
            Log out
          </Button>
        </Fragment>
      )
    } else {
      content = (
        <div id="login-box">
          <h1 className="login-header">Sneakerdraws Dashboard</h1>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item name="email" rules={[{ required: true, message: 'Enter your email' }]}>
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Enter your password' }]}>
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="w-100"
                loading={isLoggingIn}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
          <div id="env-footer">env: {process.env.REACT_APP_DEPLOY_ENV}</div>
        </div>
      )
    }
  }

  return (
    <SDStyledContainerForNotAuthenticatedUser>{content}</SDStyledContainerForNotAuthenticatedUser>
  )
}

WelcomePage.propTypes = {
  appState: PropTypes.object.isRequired,
}

export default WelcomePage

import * as Yup from 'yup'
import {
  Button,
  Col,
  Form,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Upload,
  message,
} from 'antd'
import { CloudUploadOutlined, DeleteFilled } from '@ant-design/icons'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { createRef, useState } from 'react'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

import { db, storage } from '@/services/firebase'
import { convertFirestoreTimestampToDate, logDebug } from '@/helpers/utils'
import AppPageHeader from '@/components/app-page-header'
import EditImages from './edit-images'

import './index.scss'
import ImageCard from '@/pages/admin/releases-root/edit-release/Components/ImageCard'

const { TextArea } = Input
const { Option } = Select

const FormStep = Object.freeze({
  STEP_1: 1,
  STEP_2: 2,
})

export default () => {
  const navigate = useNavigate()
  const location = useLocation()
  const form = createRef()

  const [step, setStep] = useState(FormStep.STEP_1)
  const [release, setRelease] = useState(location.state?.release && location.state.release)
  const [loading, setLoading] = useState(false)
  const [grabbed, setGrabbed] = useState([])

  const save = (values) => {
    setLoading(true)

    const data = {
      title: values.title,
      description: values.description,
      price: values.price,
      release_date: values.release_date ? values.release_date.toDate() : null,
      one_size: values.one_size === 'one_size',
      is_elite: values.is_elite === 'yes',
      location: values.region,
    }

    if (!release || values.is_template) {
      data.has_raffles = false
      data.raffle_did_run = false
      data.published = !values.is_template && !!values.release_date

      const releaseRef = doc(collection(db, 'releases'))
      const imageUploads = []
      grabbed.forEach((file) => {
        imageUploads.push(uploadImage(releaseRef.id, file))
      })

      console.log('imageUploads:', imageUploads)

      Promise.all(imageUploads)
        .then((image_urls) => {
          data.images = image_urls
          console.log('Creating:', data)
          return setDoc(releaseRef, data)
        })
        .then(async () => {
          await message.success({
            content: 'Success',
            duration: 2,
            key: 1,
          })
          setLoading(false)
          navigate(-1)
        })
        .catch(function (error) {
          setLoading(false)
          logDebug('Error saving document', error)
        })
    } else {
      const releaseRef = doc(db, 'releases', release.id)

      updateDoc(releaseRef, data)
        .then(async () => {
          await message.success({ content: 'Success', key: 1, duration: 2 })
          setLoading(false)
          navigate(-1)
        })
        .catch(function (error) {
          setLoading(false)
          logDebug('Error updating document', error)
        })
    }
  }

  const uploadImage = (releaseId, file) => {
    return new Promise((resolve, reject) => {
      const type = file.type === 'image/jpeg' ? '.jpeg' : '.png'
      const storageRef = ref(ref(ref(storage, 'releases'), releaseId), uuidv4() + type)

      uploadBytes(storageRef, file)
        .then(() => {
          getDownloadURL(storageRef).then((downloadURL) => {
            resolve(downloadURL)
          })
        })
        .catch(() => {
          reject('nope')
        })
    })
  }

  const removeFromGrab = (index) => {
    const grabbedUpdated = [...grabbed]
    grabbedUpdated.splice(index, 1)
    setGrabbed(grabbedUpdated)
  }

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Release title is required'),
    region: Yup.string().required('Region is required'),
    price: Yup.number().required('Price is required').positive().integer(),
  })

  const renderFormContent = (values, errors, setFieldValue) => {
    if (step === FormStep.STEP_1) {
      return (
        <>
          <p className="mt-3">Release title</p>
          <Form.Item hasFeedback validateStatus={errors.title && 'error'} help={errors?.title}>
            <FormikField name="title">
              {({ field }) => <Input size="large" {...field} />}
            </FormikField>
          </Form.Item>
          <p className="mt-3">Description</p>
          <Form.Item>
            <FormikField name="description">
              {({ field }) => <TextArea size="large" {...field} />}
            </FormikField>
          </Form.Item>
          <p className="mt-3">Elite</p>
          <Form.Item>
            <FormikField name="is_elite">
              {({ field }) => (
                <Select
                  {...field}
                  value={values.is_elite}
                  onChange={(val) => setFieldValue('is_elite', val)}
                  size="large"
                >
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
              )}
            </FormikField>
          </Form.Item>
          <p className="mt-3">Release type</p>
          <Form.Item>
            <FormikField name="one_size">
              {({ field }) => (
                <Select
                  {...field}
                  value={values.one_size}
                  onChange={(val) => setFieldValue('one_size', val)}
                  size="large"
                >
                  <Option value="normal">Normal</Option>
                  <Option value="one_size">One Size</Option>
                </Select>
              )}
            </FormikField>
          </Form.Item>
          <p className="mt-3">Region</p>
          <Form.Item hasFeedback validateStatus={errors.region && 'error'} help={errors?.region}>
            <FormikField name="region">
              {({ field }) => (
                <Select
                  {...field}
                  value={values.region}
                  onChange={(val) => setFieldValue('region', val)}
                  size="large"
                >
                  <Option value="Europe">Europe</Option>
                  <Option value="US">U.S.</Option>
                  <Option value="World">Worldwide</Option>
                </Select>
              )}
            </FormikField>
          </Form.Item>
          <p className="mt-3">Price</p>
          <Form.Item hasFeedback validateStatus={errors.price && 'error'} help={errors?.price}>
            <FormikField name="price">
              {({ field }) => (
                <InputNumber
                  {...field}
                  defaultValue={0}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  min={0}
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  onChange={(val) => setFieldValue('price', val)}
                  size="large"
                />
              )}
            </FormikField>
          </Form.Item>
        </>
      )
    } else if (step === FormStep.STEP_2) {
      console.log(grabbed)

      return (
        <>
          <p className="mt-3">Release date</p>
          <Form.Item>
            <FormikField
              name="release_date"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  showTime
                  format="DD.MM.YYYY HH:mm"
                  disabledDate={(current) => moment().add(-1, 'days') >= current}
                  placeholder={'TBA'}
                  minuteStep={15}
                  value={values.release_date}
                  onChange={(date, dateString) => setFieldValue('release_date', date)}
                  size="large"
                />
              )}
            />
          </Form.Item>
          {/* Visible only when creating new release. */}
          {release ? (
            <EditImages releaseId={release.id} />
          ) : (
            <>
              <div className="ant-row ant-form-item mt-3">
                <p className="mt-3">Images</p>
                <Upload
                  multiple={true}
                  listType="text"
                  beforeUpload={async (file, fileList) => {
                    if (file.size >= 5 * 1024 * 1024) {
                      await message.error('File size is too large. Must be smaller than 5MB.')
                      return false
                    }

                    await setGrabbed([...grabbed, ...fileList])
                    return false
                  }}
                  className="custom-upload w-100"
                  showUploadList={false}
                >
                  <Button className="btn-upload w-100" size="large">
                    Select images <CloudUploadOutlined />
                  </Button>
                </Upload>
              </div>
              <Row gutter={[16, 16]} className="mt-3">
                {grabbed.map((file, i) => (
                  <ImageCard
                    key={i}
                    index={i}
                    url={URL.createObjectURL(file)}
                    onDelete={removeFromGrab}
                  />
                ))}
              </Row>
            </>
          )}
        </>
      )
    }
  }

  // releaseId={release.id}

  const validateFirstStep = async (e) => {
    e.preventDefault()

    await form.current.validateField('title')
    await form.current.validateField('region')
    await form.current.validateField('price')

    if (form.current.isValid) setStep(FormStep.STEP_2)
  }

  const renderFormControls = (setFieldValue, submitForm) => {
    if (step === FormStep.STEP_1) {
      return (
        <Button
          htmlType="button"
          className="btn-submit f-grow-1"
          onClick={(e) => validateFirstStep(e)}
          size="large"
        >
          Next Step
        </Button>
      )
    } else if (step === FormStep.STEP_2) {
      return (
        <>
          <Button
            htmlType="submit"
            className="btn-outline-primary btn-template w-50"
            onClick={(e) => {
              setFieldValue('is_template', true)
              submitForm()
              e.preventDefault()
            }}
          >
            Save as Template
          </Button>
          <Button htmlType="submit" size="large" className="btn-submit w-50" loading={loading}>
            {release?.published ? 'Unpublish' : 'Publish'}
          </Button>
        </>
      )
    }
  }

  return (
    <div className="app-edit-release">
      <AppPageHeader
        mainActionEnabled={true}
        mainActionLabel="Cancel"
        onClick={() => navigate(-1)}
      />
      <Row justify="center">
        <Col span={8}>
          <h1 className="title">{release ? release.title : 'New Release'}</h1>
          <div className="form-wrapper box-shadow">
            <Formik
              innerRef={form}
              initialValues={{
                is_template: false,
                title: release ? release.title : '',
                description: release ? release.description : '',
                price: release ? release.price : undefined,
                release_date: release?.release_date
                  ? moment(convertFirestoreTimestampToDate(release.release_date))
                  : undefined,
                one_size: release && release.one_size ? 'one_size' : 'normal',
                is_elite: release && release.is_elite ? 'yes' : 'no',
                region: release ? release.location : '',
              }}
              onSubmit={(values) => save(values)}
              validationSchema={ValidationSchema}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ values, errors, setFieldValue, submitForm, validateField, isValid }) => (
                <FormikForm>
                  {renderFormContent(values, errors, setFieldValue)}
                  <div className="btn-row mt-3">
                    {renderFormControls(setFieldValue, submitForm)}
                  </div>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  )
}

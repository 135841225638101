import { Badge, Button, Col, Descriptions, Divider, Form, Input, Modal, Row } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import moment from 'moment'

import { functions } from '@/services/firebase'
import { toastError, toastSuccess } from '@/components/app-toast'

import './index.scss'

const { confirm } = Modal

const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo)

export default () => {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [role, setRole] = useState('-')

  const onFinish = (values) => {
    setLoading(true)

    const getUserByEmail = httpsCallable(functions, 'getUserByEmail')

    getUserByEmail({ email: values.email })
      .then((result) => {
        if (result.data == null) {
          toastError('User not found')
        } else {
          setUser(result.data)
          setRole('-')
          if (result.data['customClaims'] != null) {
            if (result.data['customClaims']['admin'] != null) {
              setRole('Admin')
            } else if (result.data['customClaims']['shop_admin'] != null) {
              setRole(`Admin for shop ${result.data['customClaims']['shop_admin']}`)
            }
          }
        }

        setLoading(false)
      })
      .catch(() => {
        toastError("User couldnt't be loaded")
        setLoading(false)
      })
  }

  const onFinishMakeShopAdmin = (values) => {
    const makeShopAdmin = httpsCallable(functions, 'makeShopAdmin')

    makeShopAdmin({
      uid: user['uid'],
      shop_id: values.shop_id,
    }).then(function (result) {
      if (result.data == null) {
        // message.error({ content: 'User not found.' });
      } else {
        setUser(result.data)
      }
    })
  }

  function disableUser() {
    console.log("user['uid']:", user['uid'])
    const disableUserCall = httpsCallable(functions, 'disableUser')
    disableUserCall({ uid: user['uid'] })
      .then(function (result) {
        console.log('Set. res:', result)
        toastSuccess('User is now disabled')
      })
      .catch(function (error) {
        console.log(error)
        toastError("User coulnt't be disabled")
      })
  }

  function showDisableConfirm() {
    confirm({
      title: 'Are you sure you want to disable this user?',
      icon: <ExclamationCircleOutlined />,
      content: user.displayName + ' will not be able to login next time.',
      okText: 'Disable',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        disableUser()
      },
    })
  }

  return (
    <div className="app-users">
      <Row justify="center">
        <Col>
          <h1 className="title">Find user</h1>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={8}>
          <div className="form-wrapper box-shadow">
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Enter user's identifier",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" className="btn-submit" loading={loading}>
                  Get user info
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      {user !== null && (
        <div>
          <Descriptions
            title="User Info"
            layout="vertical"
            bordered
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            extra={
              <Button type="primary" danger onClick={() => showDisableConfirm()}>
                Disable
              </Button>
            }
          >
            <Descriptions.Item label="Elite">
              <Badge
                color={user['db_data']['is_elite'] === true ? 'green' : '#ddd'}
                text={user['db_data']['is_elite'] === true ? 'Yes' : 'No'}
              />
            </Descriptions.Item>
            <Descriptions.Item label="UID">{user['uid']}</Descriptions.Item>
            <Descriptions.Item label="Enabled">
              {user['disabled'] === true ? 'No' : 'Yes'}
            </Descriptions.Item>
            <Descriptions.Item label="Name">{user['displayName']}</Descriptions.Item>
            <Descriptions.Item label="Email">
              <Badge
                color={user['emailVerified'] === true ? 'green' : 'red'}
                text={user['emailVerified'] === true ? 'Verified' : 'Not verified'}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Admin">{role}</Descriptions.Item>
            <Descriptions.Item label="Created">
              {moment(user['metadata']['creationTime']).format('DD.MM.YYYY HH:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="Last sign-in">
              {moment(user['metadata']['lastSignInTime']).format('DD.MM.YYYY HH:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="Banned">
              {user['db_data']['is_banned'] === true ? 'Yes' : 'No'}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">{user['db_data']['phone']}</Descriptions.Item>
          </Descriptions>

          <Divider />

          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinishMakeShopAdmin}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Shop ID"
              name="shop_id"
              rules={[
                {
                  required: true,
                  message: "Enter shop's identifier",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className="btn-submit">
                Make admin
              </Button>
            </Form.Item>
          </Form>
          <Divider />
        </div>
      )}
    </div>
  )
}

import { Dropdown, Menu } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

import { UserRole } from '@/constants/user-role'
import { auth } from '@/services/firebase'

import './index.scss'
import PropTypes from 'prop-types'

const SideNavHead = ({ isCollapsed }) => {
  const currentUser = auth.currentUser

  const getRoleName = () => {
    return currentUser && currentUser.role === UserRole.ADMIN ? 'Admin' : 'Store'
  }

  const onClickMenuItem = (e) => {
    if (e.key === 'signout') {
      auth.signOut().catch()
    }
  }

  const menu = (
    <Menu mode="horizontal" onClick={onClickMenuItem} style={{ backgroundColor: '#f0f2f5' }}>
      <Menu.Item key="signout" icon={<LogoutOutlined />}>
        Sign out
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="app-side-nav-head">
      <img src="/icons/ic_logo.png" alt="Sneakerdraws" className="logo" />
      {!isCollapsed && (
        <Dropdown overlay={menu} placement="bottomCenter" arrow>
          <div className="ms-2 user">
            <div className="name">{currentUser.displayName}</div>
            <div className="mt-2 role">{getRoleName()}</div>
          </div>
        </Dropdown>
      )}
    </div>
  )
}

SideNavHead.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
}

export default SideNavHead

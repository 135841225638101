import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Col, Modal } from 'antd'

const ImageCardForEditing = ({ url, index, onDelete }) => {
  const showDeleteConfirm = (index) => {
    Modal.confirm({
      title: 'Delete Image',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete this image?',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        onDelete(index)
      },
    })
  }

  return (
    <Col span={8}>
      <div className="thumbnail-wrapper">
        <img className="thumbnail" src={url} alt="" />
        <div className="thumb-overlay">
          <Button
            type="danger"
            icon={<DeleteFilled />}
            size="small"
            onClick={() => showDeleteConfirm(index)}
            className="btn-delete"
          />
        </div>
      </div>
    </Col>
  )
}

export default ImageCardForEditing

import { Navigate, Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { httpsCallable } from 'firebase/functions'
import { message } from 'antd'
import { useEffect, useState } from 'react'

import { UserRole } from '@/constants/user-role'
import { auth, functions } from '@/services/firebase'
import { default as ShopAdminReleases } from '@/pages/ShopAdmin/releases'
import AdminLayout from './pages/admin/layout'
import CreateRaffle from '@/pages/ShopAdmin/create-raffle'
import EditRelease from '@/pages/admin/releases-root/edit-release'
import Participants from '@/pages/admin/participants'
import PostEdit from '@/pages/admin/posts/post-edit'
import PostList from '@/pages/admin/posts/post-list'
import Raffles from '@/pages/admin/raffles'
import Releases from '@/pages/admin/releases-root/releases'
import ShopAdminLayout from './pages/ShopAdmin/ShopAdminLayout'
import ShopData from '@/pages/ShopAdmin/shop-data'
import ShopRaffles from '@/pages/admin/shop-raffles'
import StoreEditor from '@/pages/ShopAdmin/store-locations/store-location-edit'
import StoreList from '@/pages/admin/stores/stores-list'
import StoreLocationsPage from '@/pages/ShopAdmin/store-locations'
import Users from '@/pages/admin/users'
import WelcomePage from './pages/Welcome'
import Winners from '@/pages/ShopAdmin/Winners'

import './App.scss'
import './assets/scss/main.scss'

const App = () => {
  const defaultState = {
    authenticated: undefined,
    role: undefined,
    shopId: undefined,
    user: null,
  }
  const [state, setState] = useState(defaultState)

  const onAuthStateChanged = (user) => {
    if (user) {
      const getUserRole = httpsCallable(functions, 'getUserRole')

      getUserRole({ uid: user.uid })
        .then((result) => {
          const role = result.data.role

          setState({
            authenticated: true,
            role: role,
            shopId: result.data.shop_id ? result.data.shop_id : null,
            user: user,
          })

          if (!['admin', 'shop_admin'].includes(role)) {
            message.error('Wrong user role')
          }

          // TODO: Позже прокинуть - это Redux
          // this.props.initializeUserInfoAction({
          //   authenticated: true,
          //   role: result.data.role,
          //   shopId: result.data.shop_id ? result.data.shop_id : null,
          //   loading: false,
          // })
          // }
        })
        .catch(() => {
          setState({
            authenticated: true,
            role: null,
            shopId: null,
            user: user,
          })
        })
    } else {
      setState({
        authenticated: false,
        role: null,
        shopId: null,
        user: null,
      })
    }
  }

  useEffect(() => {
    return auth.onAuthStateChanged(onAuthStateChanged)
  }, [])

  const result = [<Toaster key="app-1" />]

  if (state.authenticated && Object.values(UserRole).includes(state.role)) {
    if (state.role === UserRole.ADMIN) {
      result.unshift(
        <Routes key="app-0">
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="releases/*">
              <Route path="" element={<Releases />} />
              <Route path="edit" element={<EditRelease />} />
              <Route path="new" element={<EditRelease />} />
              <Route path="*" element={<Navigate to="/admin/relases" />} />
            </Route>
            <Route path="raffles/:releaseId" element={<Raffles />} />
            <Route path="posts/*">
              <Route path="" element={<PostList />} />
              <Route path="edit" element={<PostEdit />} />
              <Route path="new" element={<PostEdit />} />
              <Route path="*" element={<Navigate to="/admin/posts" />} />
            </Route>
            <Route path="stores/*">
              <Route path="" element={<StoreList />} />
              <Route path="raffles/:shopId" element={<ShopRaffles />} />
              <Route path="participants" element={<Participants />} />
              <Route path="*" element={<Navigate to="/admin/stores" />} />
            </Route>
            <Route path="users/*">
              <Route path="" element={<Users />} />
              <Route path="*" element={<Navigate to="/admin/users" />} />
            </Route>
            <Route path="*" element={<Navigate to="/admin/releases" />} />
          </Route>
          <Route path="*" element={<Navigate to="/admin/releases" />} />
        </Routes>,
      )
    } else {
      result.unshift(
        <Routes key="app-0">
          <Route path="/store-admin" element={<ShopAdminLayout />}>
            <Route path="releases/*">
              <Route path="" element={<ShopAdminReleases shopId={state.shopId} />} />
              <Route path="winners" element={<Winners shopId={state.shopId} />} />
              <Route path="*" element={<Navigate to="/store-admin/relases" />} />
            </Route>
            <Route path="shop" element={<ShopData shopId={state.shopId} />} />
            <Route path="stores/*">
              <Route path="" element={<StoreLocationsPage shopId={state.shopId} />} />
              <Route path="store" element={<StoreEditor />} />
              <Route path="*" element={<Navigate to="/store-admin/stores" />} />
            </Route>
            <Route path="raffle/*">
              <Route path="new" element={<CreateRaffle shopId={state.shopId} />} />
              <Route path="edit" element={<CreateRaffle shopId={state.shopId} />} />
            </Route>
            <Route path="*" element={<Navigate to="/store-admin/releases" />} />
          </Route>
          <Route path="*" element={<Navigate to="/store-admin/releases" />} />
        </Routes>,
      )
    }
  } else {
    result.unshift(
      <Routes key="app-0">
        <Route key="unexpected-role-route-0" path="/" element={<WelcomePage appState={state} />} />
        <Route key="unexpected-role-route-1" path="*" element={<Navigate to="/" />} />
      </Routes>,
    )
  }
  return result
}

export default App
/*

class App extends Component {
  constructor() {
    super()
    this.state = {
      authenticated: undefined,
      role: '', // admin || shop_admin
      shop_id: '', // in case of shop_admin
      loading: true,
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const getUserRole = httpsCallable(functions, 'getUserRole')

        getUserRole({ uid: user.uid }).then((result) => {
          if (result.data.role === 'admin' || result.data.role === 'shop_admin') {
            this.setState({
              authenticated: true,
              role: result.data.role,
              shop_id: result.data.shop_id ? result.data.shop_id : null,
              loading: false,
            })

            this.props.initializeUserInfoAction({
              authenticated: true,
              role: result.data.role,
              shopId: result.data.shop_id ? result.data.shop_id : null,
              loading: false,
            })
          } else {
            this.setState({
              authenticated: false,
              role: '',
              shop_id: '',
              loading: false,
            })

            this.props.initializeUserInfoAction({
              authenticated: false,
              role: '',
              shopId: '',
              loading: false,
            })
            message.error('Wrong user role')
          }
        })
      } else {
        this.setState({
          authenticated: false,
          role: '',
          shop_id: '',
          loading: false,
        })
      }
    })
  }

  render() {
    console.log(auth.currentUser)

    const result = [<Toaster key="app-1" />]

    if (this.state.authenticated && ['admin', 'shop_admin'].includes(this.state.role)) {
      // return result.unshift()
    } else {
      result.unshift(
        <Routes key="app-0">
          <Route key="unexpected-role-route-0" path="/" element={<Welcome appState={this.state} />} />
          <Route key="unexpected-role-route-1" path="*" element={<Navigate to="/" />} />
        </Routes>,
      )
    }
    //
    // if (this.state.authenticated === undefined) {
    //   result.unshift(<Loader className="mt-4" />)
    // } else {
    //   result.unshift(
    //     <Routes key="app-0">
    //       <Route key="unexpected-role-route-0" path="/" element={<UnexpectedRole />} />
    //       <Route key="unexpected-role-route-1" path="*" element={<Navigate to="/" />} />
    //     </Routes>,
    //   )
    //
    /!*if (this.state.authenticated) {
                                        switch (this.state.role) {
                                          case 'admin':
                                            break
                                          case 'shop_admin':
                                            break
                                          default:
                                            result.unshift(
                                              <Routes key="app-0">
                                                <Route key="unexpected-role-route-0" path="/" element={<UnexpectedRole />} />
                                                <Route key="unexpected-role-route-1" path="*" element={<Navigate to="/" />} />
                                              </Routes>,
                                            )
                                          // code block
                                        }
                                      } else {
                                        result.unshift(
                                          <Routes key="app-0">
                                            <Route key="login-route-0" path="/" element={<Welcome />} />
                                            <Route key="login-route-1" path="*" element={<Navigate to="/" />} />
                                          </Routes>,
                                        )
                                      }*!/
    // }
    return result
    /!*return [
                                                          <Routes key="app-0">
                                                            <Fragment>

                                                              {this.state.authenticated ? (
                                                                <Route element={<Admin />}>
                                                                  <Route
                                                                    path={`${RouteRegistry.adminRoot.subroutes.releases.path}/!*`}
                                                                    element={<ReleasesRoot />}
                                                                  ></Route>
                                                                  <Route
                                                                    path="*"
                                                                    element={<Navigate to={`${RouteRegistry.adminRoot.subroutes.releases.path}`} />}
                                                                  />
                                                                </Route>
                                                              ) : (
                                                                [
                                                                  <Route key="login-route-0" path="/" element={<Welcome />} />,
                                                                  <Route key="login-route-1" path="*" element={<Navigate to="/" />} />,
                                                                ]
                                                              )}
                                                            </Fragment>
                                                            {/!*<Route*!/}
                                                            {/!*  element={(() => {*!/}
                                                            {/!*    console.log('test')*!/}

                                                            {/!*    return <AdminRoutes />*!/}
                                                            {/!*    // return this.state.authenticated === true ? (*!/}
                                                            {/!*    //   <Fragment>*!/}
                                                            {/!*    //     <AdminRoutes />*!/}
                                                            {/!*    //   </Fragment>*!/}
                                                            {/!*    // ) : (*!/}
                                                            {/!*    //   <Navigate to={{ pathname: '/' }} />*!/}
                                                            {/!*    // )*!/}
                                                            {/!*  })()}*!/}
                                                            {/!*!/>*!/}
                                                            {/!*<Route
                                                              path={`${RouteRegistry.storeAdminRoot.path}/!*`}
                                                              element={
                                                                this.state.authenticated === true ? <ShopAdmin /> : <Navigate to={{ pathname: '/' }} />
                                                              }
                                                            />*!/}
                                                            {/!*<Route
                                                              path="/"
                                                              element={
                                                                this.state.authenticated === false ? (
                                                                  <Welcome />
                                                                ) : this.state.role === 'admin' ? (
                                                                  <Navigate to={RouteRegistry.adminRoot.path} />
                                                                ) : this.state.role === 'shop_admin' ? (
                                                                  <Navigate
                                                                    to={{
                                                                      pathname: RouteRegistry.storeAdminRoot.path,
                                                                      // state: { shopId: this.state.shop_id },
                                                                    }}
                                                                  />
                                                                ) : (
                                                                  <Welcome />
                                                                )
                                                              }
                                                            />*!/}
                                                          </Routes>,
                                                          <Toaster key="app-1" />,
                                                        ]*!/
  }
}

const mapStateToProps = ({ authUser }) => {
  const { currentUser } = authUser
  return { currentUser }
}

const mapActionsToProps = {
  initializeUserInfoAction: initializeUserInfo,
}

export default connect(mapStateToProps, mapActionsToProps)(App)
*/

import { Button, Image, Space, Table, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { db } from '@/services/firebase'
import { createOrEditRaffle, initializeReleases } from '@/redux/releases/actions'
import { formatDate } from '@/helpers/data-formatter'
import AppPageHeader from '@/components/app-page-header'
import RouteRegistry from '@/constants/route-registry'

import './index.scss'
import { collection, onSnapshot, orderBy, query, doc } from 'firebase/firestore'

function Releases(props) {
  let navigate = useNavigate()

  const [releases, setReleases] = useState([])
  const { shopId } = props

  useEffect(() => {
    if (!shopId || shopId === '') return

    const promises = []
    const promiseReleases = new Promise((resolve, reject) => {
      onSnapshot(query(collection(db, 'releases'), orderBy('release_date', 'desc')), (snapshot) => {
        const allReleases = snapshot.docs.map((firestoreDoc) => {
          return {
            id: firestoreDoc.id,
            rel_date: formatDate(firestoreDoc.get('release_date'), 'DD.MM.YYYY, HH:mm'),
            ...firestoreDoc.data(),
          }
        })
        resolve({ allReleases: allReleases })
      })
    })

    promises.push(promiseReleases)

    const promiseRaffles = new Promise((resolve, reject) => {
      onSnapshot(collection(doc(db, 'shops', shopId), 'raffles'), (snapshot) => {
        const myRaffles = snapshot.docs.map((firestoreDoc) => ({
          id: firestoreDoc.id,
          ...firestoreDoc.data(),
        }))
        resolve({ myRaffles: myRaffles })
      })
    })

    promises.push(promiseRaffles)

    Promise.all(promises).then((resolve, reject) => {
      const allReleases = resolve.find((e) => e.allReleases)['allReleases']
      const myRaffles = resolve.find((e) => e.myRaffles)['myRaffles']

      // Combine data from releases & raffles
      // const mapped = allReleases.map((rel) => ({
      //   raffle: myRaffles.find((raffle) => raffle.release_ref.id === rel.id),
      //   ...rel,
      // }));

      const mapped = myRaffles
        .map((raf) => {
          const relFound = allReleases.find((rel) => rel.id === raf.release_ref.id)
          const t = relFound ? { raffleId: raf.id, raffle: raf, ...relFound } : null
          console.log(t)
          return t
        })
        .filter((i) => i !== null)

      setReleases(mapped)
      props.initializeReleasesAction(allReleases)
    })
  }, [shopId])

  function editRaffle(record) {
    props.createOrEditRaffleAction(record)
    navigate('/store-admin/raffle/edit')
  }

  function showWinners(record_id) {
    const release = releases.find((release) => release.id === record_id)

    navigate('/store-admin/releases/winners', {
      state: {
        raffle_id: release.raffle.id,
        release_title: release.title,
        release_date: release.release_date,
      },
    })
  }

  function createRaffleLink(record) {
    console.log(record.raffle)
    if (record.raffle === null) {
      const disabled = moment() > moment(record.release_date?.toDate())

      return (
        <Tooltip title={disabled ? 'Too late!' : ''}>
          <Button type="primary" disabled={disabled} className="btn-launch-draw">
            Raffle is Closed
          </Button>
        </Tooltip>
      )
    } else {
      return (
        <Button
          disabled={!record.release_date}
          onClick={() => createRaffle(record)}
          className="btn-outline-success btn-launch-draw"
        >
          Launch Drawing
        </Button>
      )
    }
  }

  function createRaffle() {
    props.createOrEditRaffleAction(releases[0])
    navigate('/store-admin/raffle/new')
  }

  const getCoverImage = (imgArray) => (imgArray.length > 0 ? imgArray[0] : '')

  const columns = [
    {
      width: 20,
      title: 'Picture',
      dataIndex: 'images',
      render: (value) => <Image width={60} height={40} src={getCoverImage(value)} />,
    },
    // {
    //   width: 20,
    //   align: "center",
    //   render: (t, record) =>
    //     record.raffle != null && moment() < moment(record.release_date.toDate()).startOf("day")
    //       ? <Badge status="processing" title="Running raffle" />
    //       : null
    // },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Is elite?',
      width: 90,
      align: 'center',
      render: (t, record) => (record.is_elite ? 'Yes' : 'No'),
    },
    {
      title: 'Price',
      width: 140,
      dataIndex: 'price',
      render: (value) => `€ ${value}`,
    },
    {
      title: 'Shipping',
      dataIndex: 'location',
      width: 180,
    },
    {
      title: 'Release Date',
      dataIndex: 'rel_date',
      width: 180,
      sorter: (a, b) => a.release_date - b.release_date,
    },
    {
      title: 'Closing Date',
      width: 180,
      render: (t, record) =>
        record.raffle.entry_allowed_till
          ? formatDate(record.raffle.entry_allowed_till, 'DD.MM.YYYY, HH:mm')
          : null,
      sorter: (a, b) => a.raffle.entry_allowed_till - b.raffle.entry_allowed_till,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          <Space>
            {createRaffleLink(record)}
            <Button
              className="btn-outline-primary"
              onClick={() => showWinners(record.id)}
              disabled={!record.raffle_did_run}
            >
              Winners
            </Button>
            <Button
              className="btn-outline-primary"
              onClick={() => editRaffle(record)}
              disabled={!!record.raffle_did_run}
            >
              Edit
            </Button>
          </Space>
        </Space>
      ),
    },
  ]

  return (
    <div className="app-shop-releases">
      <AppPageHeader
        title="Releases & Drawings"
        mainActionEnabled={true}
        mainActionLabel="New Drawing"
        onClick={() => {
          createRaffle(null)
        }}
      />
      <div className="data-wrapper round-top-1">
        <Table bordered dataSource={releases} columns={columns} rowKey="raffleId" />
      </div>
    </div>
  )
}

const mapStateToProps = ({}) => {
  return {}
}
const mapActionsToProps = {
  initializeReleasesAction: initializeReleases,
  createOrEditRaffleAction: createOrEditRaffle,
}

export default connect(mapStateToProps, mapActionsToProps)(Releases)

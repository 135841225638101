import { Component, Suspense, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { AppstoreOutlined, ShopOutlined, GlobalOutlined } from '@ant-design/icons'
import SideNavHead from '../../components/side-nav-head'

import Routes from './routes'
import RouteRegistry from '../../constants/route-registry'

import './index.scss'

const { Content, Footer, Sider } = Layout

export default () => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const onCollapse = (collapsed) => setIsCollapsed(collapsed)

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={onCollapse}
        width={250}
        className="sd-bg-primary"
      >
        <SideNavHead isCollapsed={isCollapsed} />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" className="sd-bg-primary">
          <Menu.Item icon={<AppstoreOutlined />}>
            <Link to="/store-admin/releases">Releases</Link>
          </Menu.Item>
          <Menu.Item icon={<GlobalOutlined />}>
            <Link to="/store-admin/stores">Locations</Link>
          </Menu.Item>
          <Menu.Item icon={<ShopOutlined />}>
            <Link to="/store-admin/shop">Settings</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="app-layout">
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Suspense fallback={<div className="loading" />}>
              <Outlet />
            </Suspense>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Sneakerdraws ©2020, v.{process.env.REACT_APP_VERSION}
        </Footer>
      </Layout>
    </Layout>
  )
}

import { Table } from 'antd'
import { collection, doc, getDocs, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import moment from 'moment'

import AppPageHeader from '@/components/app-page-header'

import { db } from '@/services/firebase'
import { logDebug } from '@/helpers/utils'

import './index.scss'

const Raffles = () => {
  const { releaseId } = useParams()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [raffles, setRaffles] = useState([])

  useEffect(() => {
    getRafflesForRelease().catch()
  }, [])

  const getRafflesForRelease = async () => {
    try {
      const releaseRef = doc(db, 'releases', releaseId)
      const shops = await getDocs(collection(db, 'shops'))
      const rafflePromises = []

      shops.forEach((shop) => {
        rafflePromises.push(
          getDocs(
            query(
              collection(doc(db, 'shops', shop.id), 'raffles'),
              where('release_ref', '==', releaseRef),
            ),
          ),
        )
      })

      const filtered = []
      Promise.all(rafflePromises).then((raffles) => {
        const docs = raffles.map((item) => item.docs.map((r) => r.data()))
        docs.forEach((arr) => filtered.push(...arr.map((i) => i)))
        setRaffles(filtered)
      })
    } catch (e) {
      logDebug('FETCHING RAFFLES FAILED', e)
    } finally {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'release_title',
      sorter: (a, b) => {
        return a.release_title.localeCompare(b.release_title)
      },
    },
    {
      title: 'Store Name',
      dataIndex: 'shop_hq',
      width: 400,
      render: (value) => {
        return value.shop_title
      },
    },
    {
      title: 'Notified',
      dataIndex: 'winner_list_notify_email_sent',
      width: 150,
      render: (value) =>
        value ? (
          <span className="badge-success">yes</span>
        ) : (
          <span className="badge-primary">no</span>
        ),
    },
    {
      title: 'Closing Date',
      dataIndex: 'entry_allowed_till',
      width: 210,
      render: (value) => moment(value.toDate()).format('MMMM DD, YYYY hh:mm a'),
    },
  ]

  return (
    <div className="app-release-raffles">
      <AppPageHeader
        title="Raffles"
        mainActionEnabled={true}
        mainActionLabel="Go Back"
        onClick={() => navigate(-1)}
      />
      <div className="table-wrapper border-top-radius">
        <Table bordered dataSource={raffles} columns={columns} rowKey="id" />
      </div>
    </div>
  )
}

export default Raffles

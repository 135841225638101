import {
  INITIALIZE_RELEASES,
  SELECTED,
} from '../actions';

export const initializeReleases = (payload) => ({
  type: INITIALIZE_RELEASES,
  payload: payload
})

export const createOrEditRaffle = (payload) => ({
  type: SELECTED,
  payload: payload
})
import * as Yup from 'yup'
import { Button, Col, DatePicker, Form, InputNumber, Row, Select, Spin, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { createRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'

import { createOrEditRaffle } from '@/redux/releases/actions'
import { db, dbFieldValue } from '@/services/firebase'
import { shoeSizes } from './meta-data'
import { logDebug } from '@/helpers/utils'
import AppPageHeader from '@/components/app-page-header'
import RouteRegistry from '@/constants/route-registry'

import './index.scss'

const { Option } = Select

const FormStep = Object.freeze({
  STEP_1: 1,
  STEP_2: 2,
})

function CreateRaffle({ shopId, selected, releases, createOrEditRaffleAction }) {
  const [step, setStep] = useState(FormStep.STEP_1)
  const [sizes, setSizes] = useState([])
  const [locations, setLocations] = useState([])
  const [saving, setSaving] = useState(false)
  const form = createRef(null)
  const location = useLocation()
  const navigate = useNavigate()
  const isNewRaffle = location.pathname === '/store-admin/raffle/new'
  const isReleaseReadOnly = !isNewRaffle || selected.published

  useEffect(() => {
    setSizes([...shoeSizes])

    const unsub = onSnapshot(
      query(collection(doc(db, 'shops', shopId), 'locations'), orderBy('street', 'asc')),
      (snapshot) => {
        const allLocations = snapshot.docs.map((firestoreDoc) => ({
          id: firestoreDoc.id,
          ...firestoreDoc.data(),
        }))

        setLocations(allLocations)
      },
    )

    return () => {
      unsub()
    }
  }, [])

  const getInitialShippingValue = () => {
    if (!isNewRaffle && selected.raffle) {
      if (selected.raffle.pick_up_location && selected.raffle.shipping) {
        return 'combined'
      } else if (selected.raffle.shipping) {
        return 'shipping'
      } else {
        return 'instore'
      }
    }

    return 'shipping'
  }

  function getFormSizes(form_values) {
    let _sizes = {}
    for (let [key, value] of Object.entries(form_values)) {
      if (value > 0 && key.startsWith('size.')) {
        _sizes[key.substring(5)] = value
      }
    }

    return _sizes
  }

  // const pick_up_location_id = selected.raffle ? selected.raffle.pick_up_location.location_ref.id : null;

  async function locationData(pick_up_location_id) {
    const locationRef = doc(doc(db, 'shops', shopId), 'locations', pick_up_location_id)
    const firestoreDoc = await getDoc(locationRef)

    if (firestoreDoc.exists) {
      console.log('Document data:', firestoreDoc.data())
      return firestoreDoc.data()
    } else {
      console.log('No such document!')
    }
  }

  async function shopData() {
    const locationRef = doc(db, 'shops', shopId)

    return await getDoc(locationRef)
      .then((document) => {
        if (document.exists) {
          console.log('Document data:', document.data())
          return document.data()
        } else {
          console.log('No such document!')
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error)
      })
  }

  const updateRelease = async (values) => {
    const releaseRef = await doc(db, 'releases', selected.id)

    await updateDoc(releaseRef, {
      published: true,
      release_date: values.release_date ? values.release_date.toDate() : null,
    })

    if (selected.price !== values.price) {
      await updateDoc(releaseRef, { price: values.price })
    }
  }

  async function sendForm(values) {
    try {
      setSaving(true)

      if (!isReleaseReadOnly) {
        await updateRelease(values)
      }

      const _sizes = getFormSizes(values)
      if (Object.entries(_sizes).length <= 0) {
        message.warning('Please enter at least one size.')
        setSaving(false)
        return
      }

      let raffleDoc = {
        entry_allowed_till: values.closing_date.toDate(),
        sizes: _sizes,
      }

      let loc = null
      if (values.pick_up_location != null) {
        loc = await locationData(values.pick_up_location)

        loc['location_ref'] = doc(doc(db, 'shops', shopId), 'locations', values.pick_up_location)
      }

      const shippingData = { price: 20, region: 'Europe' }

      switch (values.shipping) {
        case 'shipping':
          raffleDoc['shipping'] = shippingData

          if (!isNewRaffle && selected.raffle) {
            raffleDoc['pick_up_location'] = dbFieldValue.delete()
            raffleDoc['local_winners_percentage'] = dbFieldValue.delete()
          }

          break
        case 'instore':
          if (loc) {
            raffleDoc['pick_up_location'] = loc
          }

          if (!isNewRaffle && selected.raffle) {
            raffleDoc['shipping'] = dbFieldValue.delete()
            raffleDoc['local_winners_percentage'] = dbFieldValue.delete()
          }

          break
        case 'combined':
          raffleDoc['shipping'] = shippingData

          if (loc) {
            raffleDoc['pick_up_location'] = loc
            raffleDoc['local_winners_percentage'] = values.local_winners_percentage
          }

          break
        default:
          return
      }

      // if (!selected.raffle) {
      if (isNewRaffle) {
        raffleDoc['release_ref'] = doc(db, 'releases', selected.id)
        raffleDoc['release_title'] = selected.title
        raffleDoc['release_date'] = selected.release_date

        shopData()
          .then((shop) => {
            raffleDoc['shop_email'] = shop['email']
            raffleDoc['shop_hq'] = shop['hq']

            return setDoc(doc(collection(doc(db, 'shops', shopId), 'raffles')), raffleDoc)
          })
          .then((e) => {
            goToReleases()
          })
      } else {
        // Don't edit pick up location & shipping:
        // delete doc["pick_up_location"];
        // delete doc["shipping"];

        updateDoc(doc(doc(db, 'shops', shopId), 'raffles', selected.raffle.id), raffleDoc).then(
          () => {
            goToReleases()
          },
        )
      }
    } catch (e) {
      logDebug('>>> CREATING RAFFLE FAILED', e)
    }
  }

  const goToReleases = () => {
    navigate('/store-admin/releases')
  }

  // Takes {42.2: 10, 43: 3} and maps it into form's {size.42.2: 10, size.43: 3}
  function formEncodedSizes() {
    const currSizes = !isNewRaffle ? selected?.raffle.sizes : {}
    const _sizes = {}
    sizes.forEach((i) => (_sizes[`size.${i}`] = undefined))

    if (!isNewRaffle && selected.raffle) {
      for (let [key, value] of Object.entries(currSizes)) {
        _sizes['size.' + key] = value
      }
      // console.log("_sizes: ", _sizes);
    }

    return _sizes
  }

  const handleSelectedReleaseChange = (releaseId) => {
    const found = releases.find((i) => i.id === releaseId)
    if (found) createOrEditRaffleAction(found)
  }

  const validateFirstStep = async () => {
    await form.current.validateField('release_date')
    await form.current.validateField('closing_date')
    await form.current.validateField('price')

    if (form.current.isValid) setStep(FormStep.STEP_2)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (step === FormStep.STEP_1) {
      validateFirstStep()
    } else {
      form.current.submitForm()
    }
  }

  const ValidationSchema = Yup.object().shape({
    price: Yup.number().required('Price is required').positive().integer(),
    shipping: Yup.string().required('Please select a pick-up location'),
    release_date: Yup.date().required('Please select a release date'),
    closing_date: Yup.date().required('Please select a closing date and time'),
  })

  const getReleaseOptions = () => {
    return releases.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.title}
      </Option>
    ))
  }

  const renderFormContent = (values, errors, setFieldValue, setValues) => {
    if (step === FormStep.STEP_1) {
      return (
        <>
          <p className="mt-3">Select release</p>
          <Form.Item>
            <FormikField name="release">
              {({ field }) => (
                <Select
                  {...field}
                  value={values.release}
                  onSelect={(val) => handleSelectedReleaseChange(val)}
                >
                  {getReleaseOptions()}
                </Select>
              )}
            </FormikField>
          </Form.Item>

          <p className="mt-3">Price</p>
          <Form.Item>
            <FormikField name="price">
              {({ field }) => (
                <InputNumber
                  {...field}
                  min={0}
                  formatter={(value) => `${value}€`}
                  parser={(value) => value.replace('€', '')}
                  value={values.price}
                  onChange={(val) => setFieldValue('price', val)}
                  readOnly={isReleaseReadOnly}
                />
              )}
            </FormikField>
          </Form.Item>

          <p className="mt-3">Shipping</p>
          <Form.Item>
            <FormikField name="shipping">
              {({ field }) => (
                <Select
                  {...field}
                  value={values.shipping}
                  onChange={(val) => setFieldValue('shipping', val)}
                >
                  <Option value="shipping">Shipping (Europe, 20€)</Option>
                  <Option value="instore">In-Store</Option>
                  <Option value="combined">Shipping + In-Store</Option>
                </Select>
              )}
            </FormikField>
          </Form.Item>

          {values.shipping !== 'shipping' && <p className="mt-3">Pick-up location</p>}
          <Form.Item shouldUpdate hidden={values.shipping === 'shipping'}>
            <FormikField name="pick_up_location">
              {({ field }) => (
                <Select
                  {...field}
                  value={values.pick_up_location}
                  onChange={(val) => setFieldValue('pick_up_location', val)}
                >
                  {locations.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.street}, {item.city}
                    </Option>
                  ))}
                </Select>
              )}
            </FormikField>
          </Form.Item>

          <p className="mt-3">Release date</p>
          <Form.Item
            hasFeedback
            validateStatus={errors.release_date && 'error'}
            help={errors?.closing_date}
          >
            <FormikField name="release_date">
              {({ field }) => (
                <DatePicker
                  {...field}
                  showTime
                  format="DD.MM.YYYY HH:mm"
                  disabledDate={(current) => moment().add(-1, 'days') >= current}
                  placeholder={'TBA'}
                  minuteStep={15}
                  value={values.release_date}
                  onChange={(date, dateString) => setFieldValue('release_date', date)}
                  disabled={isReleaseReadOnly}
                  allowClear={false}
                />
              )}
            </FormikField>
          </Form.Item>

          <p className="mt-3">Closing date and time</p>
          <Form.Item
            hasFeedback
            validateStatus={errors.closing_date && 'error'}
            help={errors?.closing_date}
          >
            <FormikField name="closing_date">
              {({ field }) => (
                <DatePicker
                  {...field}
                  showTime
                  minuteStep={15}
                  format="DD.MM.YYYY HH:mm"
                  disabledDate={(current) => moment().add(-1, 'days') >= current}
                  value={values.closing_date}
                  onChange={(date, dateString) => setFieldValue('closing_date', date)}
                  allowClear={false}
                />
              )}
            </FormikField>
          </Form.Item>

          {values.shipping === 'combined' && <p className="mt-3">Locals winners percentage</p>}
          <Form.Item shouldUpdate hidden={values.shipping !== 'combined'}>
            <FormikField name="local_winners_percentage">
              {({ field }) => (
                <InputNumber
                  {...field}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                  value={values.local_winners_percentage}
                  onChange={(val) => setFieldValue('local_winners_percentage', val)}
                />
              )}
            </FormikField>
          </Form.Item>
        </>
      )
    } else {
      return selected.one_size == true ? (
        <div>
          <Form.Item label="One size" className="shoe-size-form-item">
            <FormikField name="size.8000">
              {({ field }) => (
                <InputNumber
                  {...field}
                  min={0}
                  placeholder="Pairs"
                  value={values[field.name]}
                  onChange={(val) => setValues({ ...values, [field.name]: val })}
                />
              )}
            </FormikField>
          </Form.Item>
        </div>
      ) : (
        <div>
          {sizes.map((size) => (
            <Form.Item key={`size.${size}`} label={size.toString()} className="shoe-size-form-item">
              <FormikField name={`size.${size}`}>
                {({ field }) => (
                  <InputNumber
                    {...field}
                    min={0}
                    placeholder="Pairs"
                    value={values[field.name]}
                    onChange={(val) => setValues({ ...values, [field.name]: val })}
                  />
                )}
              </FormikField>
            </Form.Item>
          ))}
        </div>
      )
    }
  }

  return (
    <div className="app-create-edit-raffle">
      <AppPageHeader
        mainActionEnabled={true}
        mainActionLabel="Cancel"
        mainActionIcon={<CloseOutlined />}
        onClick={() => navigate(-1)}
      />

      <Row justify="center" className="mt-3">
        <Col span={8}>
          <h1 className="title">
            {step === FormStep.STEP_1 ? 'New Drawing' : 'Select available sizes (EU)'}
          </h1>
          <div className="form-wrapper box-shadow">
            <Spin spinning={saving} tip="Saving...">
              <Formik
                innerRef={form}
                enableReinitialize
                initialValues={{
                  release: selected.id,
                  price: selected.price,
                  release_date: selected.release_date
                    ? moment(selected.release_date.toDate())
                    : undefined,
                  closing_date: !isNewRaffle
                    ? moment(selected.raffle.entry_allowed_till.toDate())
                    : undefined,
                  // pick_up_location: location.state.edit_raffle
                  //   ? location.state.edit_raffle.pick_up_location_id
                  //   : null,
                  pick_up_location: !isNewRaffle
                    ? selected.raffle?.pick_up_location?.location_ref.id
                    : null,
                  shipping: getInitialShippingValue(),
                  local_winners_percentage: !isNewRaffle
                    ? selected.raffle?.local_winners_percentage
                    : 0,
                  ...formEncodedSizes(),
                }}
                onSubmit={(values) => sendForm(values)}
                validationSchema={ValidationSchema}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ values, errors, setFieldValue, setValues }) => (
                  <FormikForm>
                    {renderFormContent(values, errors, setFieldValue, setValues)}

                    <div className="btn-row mt-3">
                      <Button htmlType="button" className="btn-submit mt-3" onClick={handleSubmit}>
                        {step === FormStep.STEP_1 ? 'Next step' : 'Create Raffle'}
                      </Button>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = ({ raffleReleases }) => {
  const { releases, selected } = raffleReleases

  return { releases, selected }
}
const mapActionsToProps = {
  createOrEditRaffleAction: createOrEditRaffle,
}

export default connect(mapStateToProps, mapActionsToProps)(CreateRaffle)

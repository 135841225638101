import { Button, Col, Form, Input, Row, Select, Spin, message } from 'antd'
import { findIndex } from 'lodash/array'
import { isEqual } from 'lodash/lang'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'

import { auth, db } from '@/services/firebase'
import RouteRegistry from '@/constants/route-registry'

import './index.scss'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'

const { Option } = Select

const countries = [
  { name: 'Germany', code: 'DE' },
  { name: 'France', code: 'FR' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Scotland', code: 'GB' },
  { name: 'England', code: 'GB' },
  { name: 'Wales', code: 'GB' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Northern Ireland', code: 'GB' },
  { name: 'Spain', code: 'ES' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Sweden', code: 'SE' },
]

function StoreEditor() {
  const [form] = Form.useForm()
  const [saving, setSaving] = useState(false)
  const currentUser = auth.currentUser
  const location = useLocation()
  const navigate = useNavigate()
  let store = null

  console.log(location.state)

  if (location.state !== null) {
    store = location.state.store

    form.setFieldsValue({
      country: findIndex(countries, (country) => {
        return store.country_code instanceof Object
          ? isEqual(store.country_code, country)
          : store.country_code === country.code
      }),
    })
  }

  const onFinish = (values) => {
    console.log('Form values:', values)

    setSaving(true)

    getDoc(doc(db, 'shops', currentUser.shopId)).then(function (shopDoc) {
      const shopData = shopDoc.data()

      const firestoreDocData = {
        shop_title: shopData.title,
        street: values.street,
        postal_code: values.postal_code,
        city: values.city,
        state: values.state,
        country_code: countries[parseInt(values.country)],
      }

      if (location.state != null) {
        updateDoc(
          doc(doc(db, 'shops', currentUser.shopId), 'locations', location.state.store.id),
          firestoreDocData,
        )
          .then(() => {
            return updateHQ(firestoreDocData)
          })
          .then(() => {
            return updateShopRaffles(firestoreDocData)
          })
          .then(() => {
            goToStores()
          })
          .catch(function (error) {
            console.log('Error :', error)
            setSaving(false)
            message.error('Failed to save.')
          })
      } else {
        setDoc(doc(doc(db, 'shops', currentUser.shopId), 'locations'), firestoreDocData)
          .then(() => {
            goToStores()
          })
          .catch(function (error) {
            console.log('Error :', error)
            setSaving(false)
            message.error('Failed to save.')
          })
      }
    })
  }

  const updateShopRaffles = (document) => {
    return new Promise((resolve, reject) => {
      const locationRef = doc(
        doc(db, 'shops', currentUser.shopId),
        'locations',
        location.state.store.id,
      )

      getDocs(
        query(
          collection(doc(db, 'shops', currentUser.shopId), 'raffles'),
          where('pick_up_location.location_ref', '==', locationRef),
        ),
      ).then(async (querySnapshot) => {
        const raffles = querySnapshot.docs.map((raffleDoc) => raffleDoc.ref)

        document['location_ref'] = locationRef

        const batch = writeBatch(db)

        for (let i = 0; i < raffles.length; i++) {
          batch.update(raffles[i], { pick_up_location: document })
        }

        await batch.commit()
      })
    })
  }

  const updateHQ = (document) => {
    return new Promise((resolve, reject) => {
      if (location.state.store.hq !== true) {
        resolve(true)
        console.log('Not an actual HQ, no need to update.')
        return
      }

      return updateDoc(doc(db, 'shops', currentUser.shopId), { hq: document })
    })
  }

  const goToStores = () => {
    navigate('/store-admin/stores')
  }

  return (
    <div className="app-store-location-edit">
      <Row justify="center">
        <Col>
          <h1 className="title">{store != null ? 'Edit Location' : 'Create Location'}</h1>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={8}>
          <div className="form-wrapper box-shadow">
            <Spin spinning={saving} tip="Saving...">
              <Form
                form={form}
                name="dynamic_form_item"
                layout="vertical"
                initialValues={{
                  street: store && store.street,
                  postal_code: store && store.postal_code,
                  city: store && store.city,
                  state: store && store.state,
                }}
                onFinish={onFinish}
              >
                <Form.Item
                  label="Street"
                  name="street"
                  className="mt-3"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Postal code"
                  name="postal_code"
                  className="mt-3"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  className="mt-3"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="State"
                  name="state"
                  className="mt-3"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="country"
                  className="mt-3"
                  label="Country"
                  rules={[{ required: true, message: 'Required' }]}
                >
                  <Select>
                    {countries.map((country, idx) => (
                      <Option key={`country-${idx}`} value={idx}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="btn-submit mt-3">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default StoreEditor

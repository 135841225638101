import { Table, Button, Image, Space, Modal, Spin, notification, Tooltip, InputNumber } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
  writeBatch,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { shuffle } from 'lodash/collection'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import firebase from '@firebase/app-compat'
import moment from 'moment'

import { db, functions } from '@/services/firebase'
import { logDebug } from '@/helpers/utils'
import AppPageHeader from '@/components/app-page-header'
import Badge from '@/components/badge'
import RouteRegistry from '@/constants/route-registry'

import './index.scss'

const { confirm } = Modal

export default () => {
  const [creatingRaffle, setCreatingRaffle] = useState(false)
  const [releases, setReleases] = useState([])
  const eliteUsersPercentage = 80
  const navigate = useNavigate()

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, 'releases'), orderBy('release_date', 'desc')),
      (snapshot) => {
        const allReleases = snapshot.docs.map((firestoreDoc) => {
          return { ...firestoreDoc.data(), id: firestoreDoc.id }
        })
        setReleases(allReleases)
      },
    )

    return () => {
      unsub()
    }
  }, [])

  const goToReleaseEditingPage = (releaseId) => {
    const release = releases.find((release) => release.id === releaseId)

    navigate('/admin/releases/edit', {
      state: {
        release: release,
      },
    })
  }

  const deleteRelease = async (releaseId) => {
    try {
      await deleteDoc(doc(db, 'releases', releaseId))
    } catch (e) {
      logDebug('>>> DELETING RELEASE FAILED', e)
    }
  }

  const goToRafflesPage = (releaseId) => {
    navigate(`/admin/raffles/${releaseId}/`)
  }

  const showDeleteConfirm = (releaseId, releaseTitle) => {
    confirm({
      title: 'Are you sure you want to delete this release?',
      icon: <ExclamationCircleOutlined />,
      content: releaseTitle + ' and all its raffles will be gone forever.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteRelease(releaseId).catch()
      },
    })
  }

  function raffleConfirmContent() {
    return (
      <>
        Elite winners:&nbsp;
        <InputNumber
          defaultValue={eliteUsersPercentage}
          min={0}
          max={100}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
          onChange={(value) => (eliteUsersPercentage = value)}
        />
      </>
    )
  }

  function showRaffleConfirm(documentId, releaseTitle, firstImageUrl, is_elite) {
    confirm({
      title: "Are you sure you want to raffle '" + releaseTitle + "'?",
      icon: <ExclamationCircleOutlined />,
      content: raffleConfirmContent(),
      okText: 'Raffle',
      cancelText: 'Cancel',
      onOk() {
        raffle(documentId, firstImageUrl, is_elite)
      },
    })
  }

  function raffle(documentId, firstImageUrl, is_elite) {
    logDebug('RAFFLE DOCUMENT ID', documentId)

    setCreatingRaffle(true)

    const releaseRef = doc(db, 'releases', documentId)

    // 1. Get 2 matrixes
    Promise.all([getShopSizesMatrix(releaseRef), getUserMatrix(releaseRef, is_elite)]).then(
      function (result) {
        const shopSizes = result[0]
        const users = result[1] // Users is an already shuffled array

        const reducer = (accumulator, currentValue) => accumulator + currentValue.pairs
        const sum_pairs = shopSizes.reduce(reducer, 0)

        let max_elite_users = Math.round((eliteUsersPercentage / 100) * sum_pairs)

        const reducerLocalElite = (accumulator, currentValue) =>
          accumulator + currentValue.pairs * (currentValue.localWinnersPercentage / 100)
        let maxlocalPairsElite = Math.round(shopSizes.reduce(reducerLocalElite, 0))

        const reducerLocalNormal = (accumulator, currentValue) =>
          accumulator + currentValue.pickUpLocation
            ? Math.round(currentValue.pairs * ((100 - currentValue.localWinnersPercentage) / 100))
            : currentValue.pairs
        let maxlocalPairsNormal = shopSizes.reduce(reducerLocalNormal, 0)

        // 2.Iterate over shopSizes
        shopSizes.forEach((shopSize) => {
          for (let i = 0; i < shopSize.pairs; i++) {
            const isWinner = (user) => {
              return (
                parseInt(user.size) === shopSize.size &&
                user.shops.includes(shopSize.shop) &&
                user.is_banned === false &&
                !user.w_shop
              )
            }

            const isEliteWinner = (user) =>
              parseInt(user.size) === shopSize.size &&
              user.shops.includes(shopSize.shop) &&
              user.is_banned === false &&
              user.is_elite &&
              !user.w_shop

            var winnerIdx = -1

            if (is_elite && max_elite_users > 0) {
              winnerIdx = users.findIndex(isEliteWinner)
              const userWon = users[winnerIdx]

              if (userWon?.use_shipping && maxlocalPairsElite > 0 && shopSize?.pickUpLocation) {
                max_elite_users--
                maxlocalPairsElite--
                logDebug('>>> ELITE WINNER INDEX (LOCAL): ', winnerIdx)
              } else {
                max_elite_users--
                logDebug('>>> ELITE WINNER INDEX: ', winnerIdx)
              }
              logDebug('>>> MAX ELITE USERS LEFT: ', max_elite_users)
            }

            if (winnerIdx === -1) {
              winnerIdx = users.findIndex(isWinner)
              const userWon = users[winnerIdx]

              if (userWon?.use_shipping && maxlocalPairsNormal > 0) {
                maxlocalPairsNormal--
              }

              logDebug('>>> WINNER INDEX: ', winnerIdx)
            }

            if (winnerIdx > -1) {
              users[winnerIdx].w_shop = shopSize.shop
              users[winnerIdx].raffleId = shopSize.raffleId

              if (users[winnerIdx]?.use_shipping && shopSize?.pickUpLocation)
                users[winnerIdx].w_pickUpLocation = shopSize.pickUpLocation

              if (shopSize?.shipping) users[winnerIdx].w_shipping = shopSize.shipping
            } else {
              console.warn('winner not found')
            }
          }
        })

        const winners = users.filter((user) => !!user.w_shop)
        const losers = users.filter((user) => !user.w_shop)

        return updateLosers(losers, firstImageUrl).then(function () {
          updateWinners(releaseRef, winners, firstImageUrl)
        })
      },
    )
  }

  // Returned matrix: shop | size | pairs
  function getShopSizesMatrix(releaseRef) {
    return new Promise((resolve, reject) => {
      getDocs(query(collectionGroup(db, 'raffles'), where('release_ref', '==', releaseRef)))
        .then((querySnapshot) => {
          const shopSizes = []
          querySnapshot.forEach((shopSizeDoc) => {
            for (let [size, pairs] of Object.entries(shopSizeDoc.get('sizes'))) {
              shopSizes.push({
                raffleId: shopSizeDoc.ref,
                shop: shopSizeDoc.ref.parent.parent.id,
                size: parseFloat(size),
                pairs: pairs,
                pickUpLocation: shopSizeDoc.get('pick_up_location') || null,
                shipping: shopSizeDoc.get('shipping') || null,
                localWinnersPercentage: shopSizeDoc.get('local_winners_percentage') || null,
              })
            }
          })
          resolve(shuffle(shopSizes))
        })
        .catch((error) => {
          console.error('Error getting getShopSizesMatrix: ', error)
          reject(error)
        })
    })
  }

  // Returned matrix: user | userRaffleRef | size | shops (array)
  function getUserMatrix(releaseRef, elite_only) {
    return new Promise((resolve, reject) => {
      let ref = query(collectionGroup(db, 'user_raffles'), where('release_ref', '==', releaseRef))

      if (elite_only) {
        ref = query(ref, where('is_elite', '==', true))
      }

      getDocs(ref)
        .then((querySnapshot) => {
          var users = []
          querySnapshot.forEach((userRaffle) => {
            const user = userRaffle.ref.parent.parent.id
            const userName = userRaffle.get('user_name')
            const userEmail = userRaffle.get('user_email')
            const userAvatar = userRaffle.get('user_avatar')
            const is_banned = userRaffle.get('is_banned')
            const is_elite = userRaffle.get('is_elite')
            const phone = userRaffle.get('phone')
            const address = userRaffle.get('address')
            const use_shipping = userRaffle.get('use_shipping')
            const userRaffleRef = userRaffle.ref
            const size = userRaffle.get('size')
            const shops = userRaffle.get('entered_shop_refs').map((locRef) => locRef.id)
            const element = {
              user: user,
              userName: userName,
              userEmail: userEmail,
              userRaffleRef: userRaffleRef,
              size: size,
              shops: shops,
            }

            element.userAvatar = userAvatar !== undefined ? userAvatar : null
            element.is_banned = is_banned !== undefined ? is_banned : false
            element.is_elite = is_elite !== undefined ? is_elite : false
            element.phone = phone !== undefined ? phone : null
            element.address = address !== undefined ? address : null
            element.use_shipping = use_shipping !== undefined ? use_shipping : false
            console.log('user element: ', element)
            users.push(element)
          })
          resolve(shuffle(users))
        })
        .catch((error) => {
          console.error('Error getting getUserMatrix: ', error)
          reject(error)
        })
    })
  }

  // Batch writes to db for release (mark as done), winners & shops
  const updateWinners = async (releaseRef, winners, firstImageUrl) => {
    if (winners.length === 0) {
      notification['warning']({
        message: 'No winners',
        description: 'There is not a single winner in this raffle 😕',
      })
      setCreatingRaffle(false)
      return
    } else if (winners.length > 500 / 2 - 1) {
      // Also consider updates for shops and release as separate updates
      notification['error']({
        message: 'Technical limit reached',
        description: "Can't process more than 500 winners. Contact the dev...",
      })
      setCreatingRaffle(false)
      return
    }

    console.log('Writing winners for releaseId: ', releaseRef.id)
    console.log(winners)

    const batch = writeBatch(db)

    winners.forEach((winner) => {
      // debugger
      let winnerData = {
        did_win: true,
        did_show_win_popup_in_app: false,
        image_url: firstImageUrl,
      }
      if (winner.use_shipping) {
        winnerData['won_shipping'] = winner.w_shipping
      } else {
        winnerData['won_instore'] = winner.w_pickUpLocation
      }
      batch.update(winner.userRaffleRef, winnerData)

      let newWinnerData = {
        name: winner.userName,
        size: winner.size,
        avatar: winner.userAvatar,
        email: winner.userEmail,
        phone: winner.phone,
        address: winner.address,
        use_shipping: winner.use_shipping,
        is_elite: winner.is_elite,
      }

      if (!winner.use_shipping) {
        newWinnerData.pick_up = true
      }

      batch.set(doc(winner.raffleId, 'winners'), newWinnerData)
    })

    batch.update(releaseRef, {
      raffle_did_run: true,
      raffle_ran_on: firebase.firestore.Timestamp.fromDate(new Date()),
    })

    await batch
      .commit()
      .then(function () {
        // Call firebase func to trigger shop notifications for this `releaseRef`
        console.log(`Call firebase func to trigger shop notifications for ${releaseRef.id}`)

        var notifyShopsAboutWinners = httpsCallable(functions, 'notifyShopsAboutWinners')
        return notifyShopsAboutWinners({ releaseId: releaseRef.id })
      })
      .then(function () {
        setCreatingRaffle(false)

        notification['success']({
          message: 'Done',
          description: 'Raffle completed. Winners and shops were notified.',
        })

        console.log('Raffle complete. Shops notified.')
      })
      .catch(function (error) {
        // var code = error.code;
        // var message = error.message;
        // var details = error.details;
        console.log('error! ')
        console.log(error)
      })
  }

  // Set `did_win = false` so that the loosers immidiately see if they've lost
  function updateLosers(losers, firstImageUrl) {
    console.log('Updating losers in batches:', losers.length)

    const promises = []
    var i,
      j,
      chunk = 500
    for (i = 0, j = losers.length; i < j; i += chunk) {
      const chunkOfLosers = losers.slice(i, i + chunk)
      console.log('added to losers chunk: ', chunkOfLosers.length)
      promises.push(updateChunkOfLosers(chunkOfLosers, firstImageUrl))
    }
    return Promise.all(promises)
  }

  function updateChunkOfLosers(losers, firstImageUrl) {
    console.log('Starting batch of losers:', losers.length)
    const batch = writeBatch(db)
    losers.forEach((loser) => {
      batch.update(loser.userRaffleRef, {
        did_win: false,
        did_show_win_popup_in_app: false,
        image_url: firstImageUrl,
      })
    })
    return batch.commit()
  }

  const getCoverImage = (imgArray) => (imgArray.length > 0 ? imgArray[0] : '')
  const getReleaseStatus = (record) => {
    if (record.published) {
      if (record.raffle_did_run || record.raffle_ran_on) return 'Closed'
      else if (record.has_raffles) return 'In Progress'
      else return 'Coming Soon'
    } else {
      return 'Unpublished'
    }
  }

  const columns = [
    {
      width: 20,
      title: 'Picture',
      dataIndex: 'images',
      render: (value) => <Image width={60} height={40} src={getCoverImage(value)} />,
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: <ExclamationCircleOutlined />,
      width: 30,
      align: 'center',
      render: (t, record) =>
        record.is_elite ? (
          <img src="/icons/ic_elite.jpeg" alt="Elite product" className="ic-elite" />
        ) : null,
    },
    {
      title: 'Price',
      width: 140,
      dataIndex: 'price',
      render: (value) => `€${value}`,
    },
    {
      title: 'Shipping',
      width: 50,
      dataIndex: 'location',
      render: (value) => value,
    },
    {
      title: 'Release Date',
      dataIndex: 'release_date',
      width: 180,
      render: (value) => (value ? moment(value.toDate()).format('DD.MM.YYYY, HH:mm') : 'TBA'),
      // sorter: (a, b) => a.release_date - b.release_date,
    },
    {
      title: 'Release Status',
      dataIndex: '',
      width: 160,
      render: (record) => (
        <Badge
          text={getReleaseStatus(record)}
          outline={true}
          color="primary"
          className="d-flex align-items-center"
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          <Button className="btn-outline-warning" onClick={() => goToRafflesPage(record.id)}>
            Drawings
          </Button>
          <Tooltip
            title={
              record.raffle_ran_on
                ? 'This raffle ran on ' +
                  moment(record.raffle_ran_on.toDate()).format('DD.MM.YYYY, HH:mm')
                : ''
            }
          >
            <Button
              disabled={record.raffle_did_run || record.raffle_ran_on}
              className="btn-outline-success"
              onClick={() =>
                showRaffleConfirm(
                  record.id,
                  record.title,
                  record.images[0],
                  record.is_elite || false,
                )
              }
            >
              Raffle
            </Button>
          </Tooltip>
          <Button
            className="btn-outline-danger"
            onClick={() => showDeleteConfirm(record.id, record.title)}
          >
            Delete
          </Button>
          <Button className="btn-outline-primary" onClick={() => goToReleaseEditingPage(record.id)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <div className="app-release-list">
      <AppPageHeader
        title="Releases"
        mainActionEnabled={true}
        mainActionLabel="New Release"
        onClick={() => navigate('/admin/releases/new')}
      />
      <div className="posts-table-wrapper round-top-1">
        <Spin spinning={creatingRaffle} tip="Creating a raffle, please wait...">
          <Table bordered dataSource={releases} columns={columns} rowKey="id" />
        </Spin>{' '}
      </div>
    </div>
  )
}
